import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import CustomDataTable from '../CustomDataTable';
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { loadTenderAction } from "../../store/actions/action";
import Loader from '../Loader';
import Cookies from 'js-cookie'
import moment from "moment";

import './style.scss';

const ListEtender = (props) => {
    var lang = Cookies.get('lang');
    useEffect(() => {
        fetchTender(1);
    }, [lang]);

    function fetchTender(page = 1, limit = 10, sort = "") {
        let params = {
            "tender_limit": limit,
            "page": page,
            "sort": sort
        };
        props.loadTenderAction(params);
    }
    /**  Table configurations */
    const columns = [
        {
            name: props.t('Etender.slno'),
            selector: 'serial',
            width: "10%",
            cell: (item, index) => {
                return props.etender.per_page !== undefined && props.etender.per_page !== null ? (
                    <span> {props.etender.per_page * (props.etender.current_page - 1) + (index + 1)}</span>
                ) : (
                        <span> {index + 1}</span>
                    );
            }
        },
        {
            name: props.t('Etender.tender-head'),
            selector: 'title',
            sortable: true,
            width: "60%",
            cell: (item) => {
                {
                    return item.document !== undefined && item.document !== null ? (
                        <Link className="heading_font-style table-link" to={{ pathname: item.document }} target="_blank" >
                            {item.title}
                        </Link>)
                        : (
                            <span> {item.title}</span>
                        );
                }
            }
        },
        {
            name: props.t('Etender.tender-date'),
            selector: 'date',
            sortable: false,
            width: "30%",
            cell: (item) => {
                return moment(item.date).format("DD/M/YYYY");
            }
        },
    ];

    return (
        <Grid className="allTenders">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('Etender.title')}</h5>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        {props.loading && <Loader />}
                        {
                            !props.loading && props.etender !== undefined ?
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    className="paginate-container"
                                >
                                    <CustomDataTable
                                        noHeader={true}
                                        columns={columns}
                                        tableData={props.etender}
                                        tableAction={fetchTender}
                                        striped={true}
                                        pagination={true}
                                        paginationServer={true}
                                        className="table table-bordered table-striped tender_table_style stripe"
                                    />
                                </Grid> : ""
                        }
                    </Grid >
                </Grid>
            </Grid>
        </Grid >
    )
};

const mapStateToProps = state => {
    return {
        etender: state.tender,
        loading: state.app.loading
    }
};

export default connect(mapStateToProps, { loadTenderAction })(withTranslation('common')(withRouter(ListEtender)));
