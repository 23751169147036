import { LOADING, LOADED, LOAD_LANGUAGE_SUCCESSFUL, LOAD_VISITOR_SUCCESSFUL } from "../actions/type";

const init = {
    loading: false,
    message: '',
    selectedLanguage: '',
    visitorCount: 1,
    documents: {
        document_name: '',
        document_type: '',
        document_file: '',
        removed: false
    },
};

const appReducer = (state = init, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case LOADED: {
            return {
                ...state,
                loading: false,
                message: action.data || 'Loaded Successfully',
            };
        }
        case LOAD_LANGUAGE_SUCCESSFUL: {
            return {
                ...state,
                selectedLanguage: action.data
            }
        }
        case LOAD_VISITOR_SUCCESSFUL: {
            return {
                ...state,
                visitorCount: action.data
            }
        }
        default:
            return state;
    }
};

export default appReducer