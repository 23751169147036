import React, { Fragment, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { withTranslation } from "react-i18next";
import Loader from '../Loader';
import ReactPlayer from 'react-player'
import Modal from 'react-responsive-modal';
import { withRouter } from "react-router-dom";

import 'react-image-lightbox/style.css';
import './style.scss';
import "react-responsive-modal/styles.css";

const ListGalleryVideo = (props) => {
    const video_list = useSelector(state => state.gallery.videoList);
    const loading = useSelector(state => state.app.loading);
    const [isOpen, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    function onOpenHandler(id) {
        setOpen(true);
        setPhotoIndex(id);
    }

    const closeModal = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <Grid container className="container mt-5">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('VideoList.Title')}</h5>
                        <Divider/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="allImages">
                <Grid container className="container">
                        {loading && <Loader />}
                        {!loading &&video_list !== undefined && video_list.length>0 ? video_list.map((item,index) => {
                            return (
                                <Grid key={index} item sm={6} md={4} xs={12} onClick={() => onOpenHandler(index)}>
                                    <Grid className="expertTeam-gallery video-grid mb-15" >
                                        <Grid className="thumb">
                                            <img className="gallery-image" src={item.image} alt="GalleryImages"/>
                                        </Grid>
                                        <Grid className="content">
                                            <h4>{item.title}</h4>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        )
                    }) : <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        className="no-data"
                    >
                            <h5 className="">{props.t("VideoList.noData")}</h5>
                        </Grid>}
                </Grid>
                {video_list !== undefined && video_list.length > 0 ?
                    <Modal
                        open={isOpen}
                        onClose={closeModal}
                        closeOnOverlayClick={true}
                        center
                        classNames={{
                            closeIcon: 'fill-grey-500',
                            closeButton: 'close-brdr',
                            modal: 'custom-modal'
                        }}
                        closeIconSize={20}
                    >
                        <div className="modal-body bgc-white" >
                            <div className="player-wrapper">
                                <ReactPlayer
                                    className="videoFrame react-player"
                                    url={video_list !== undefined && video_list.length > 0 ? video_list[photoIndex].video : ""}
                                    width='100%'
                                    height='100%'
                                    playing
                                    controls
                                />
                            </div>
                        </div>
                    </Modal>
                    : ""
                }
            </Grid>
        </Fragment>
    )
};

export default (withTranslation('common')(withRouter(ListGalleryVideo)));






