import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import ListAnnouncements from "../../components/ListAnnouncements";
import Footer from "../../components/Footer";
import {loadAnnouncementAction} from "../../store/actions/action";
import SubImage from "../../components/SubImage";
import Cookies from 'js-cookie'

class AnnoucementList extends Component {
    state = {
        lang: ''
    };

    componentDidMount() {
        if (Object.keys(this.props.Announcements).length === 0) {
            this.props.loadAnnouncementAction();
        }
        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            props.loadAnnouncementAction()
        }
        return true;
    }

    render(){
        return (
            <Fragment>
                <SubImage/>
                <ListAnnouncements/>
                <Footer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        Announcements: state.announcements
    }
};

export default connect(mapStateToProps, {loadAnnouncementAction})(AnnoucementList);