import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#dee2e6',
            backgroundColor: '#faa519'
        },
    },
    headCells: {
        style: {
            color: 'white',
            borderRightColor: '#dee2e6',
            backgroundColor: '#faa519',
            fontWeight: 900,
            fontSize: 15,
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
            },
        },
    },
    cells: {
        style: {
            fontWeight: 200,
            fontSize: 13,
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#dee2e6',
            },
        },
    },
};

const CustomDataTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState();
    const [newPerPage, setPerPage] = useState(10);

    const handlePageChange = page => {
        setLoading(true);
        props.tableAction(page, newPerPage, sort);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPerPage(newPerPage);
        props.tableAction(page, newPerPage);
        setLoading(false);
    };

    const handleSort = (column, sortDirection) => {
        setLoading(true);
        let sort = { field: column.selector, direction: sortDirection }
        setSort(sort);
        setTimeout(() => {
            props.tableAction(1, 10, sort);
            setLoading(false);
        }, 100);
    };
    return (
        <DataTable
            title={props.title}
            noHeader={props.noHeader}
            className={props.className}
            columns={props.columns}
            data={props.tableData.data}
            progressPending={loading}
            persistTableHead={true}
            customStyles={customStyles}
            onSort={handleSort}
            sortServer
            striped={props.striped}
            pagination={props.pagination}
            paginationServer={props.paginationServer}
            paginationTotalRows={props.tableData.total}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
        />
    );
};

export default CustomDataTable;


