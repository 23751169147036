import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Cookies from 'js-cookie'
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import CMSSection from "../../components/CMSSection";

import {loadPageAction, loadHomeAction} from "../../store/actions/action";

import './style.scss';
class Page extends Component {
    state = {
        slug: '',
        lang: ''
    };

    componentDidMount() {

        if(Object.keys(this.props.home).length === 0){
            let params = {
                "banner_limit" : 4,
                "store_limit" : 16,
                "ansment_limit": 30
            };
            this.props.loadHomeAction(params);
        }

        let slug = this.props.match.params.slug;
        this.props.loadPageAction(slug);
        this.setState({
            slug: slug,
            lang: Cookies.get('lang')
        });
    }

    static getDerivedStateFromProps(props, state) {
        let slug = props.match.params.slug;
        if (Object.keys(props.page).length > 0 && state.slug != "" && state.slug !== slug) {
            state.slug = slug;
            props.loadPageAction(slug);
        }else{
            if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
                state.lang = props.selectedLanguage;
                let slug = props.match.params.slug;
                props.loadPageAction(slug);
            }
        }
        return true;
    }

    render() {

        return (
            <Fragment>
                <SubImage/>
                <CMSSection/>
                <Footer/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        home: state.home,
        selectedLanguage: state.app.selectedLanguage,
        page: state.page
    }
};

export default connect(mapStateToProps, {loadPageAction, loadHomeAction})(withRouter(Page));