import React, { useEffect, useState, Fragment } from "react";

import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { loadSitemapAction } from "../../store/actions/action";
import Loader from "../Loader";
import Cookies from "js-cookie";

import "./style.scss";

const SiteMap = props => {
    useEffect(() => {
        props.loadSitemapAction();
    }, [Cookies.get("lang")]);

    return (
        <Grid>
            <section className="mt-md-5 mt-3 mb-3 mb-md-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 Blog_Style">
                            <h2>{props.t("SiteMap.Title")}</h2>
                            <Divider className="site-map-hr" />
                        </div>
                    </div>
                    <div className="row ">
                        {props.loading && <Loader />}
                        {!props.loading &&
                            props.sitemapData !== undefined &&
                            Object.keys(props.sitemapData).length != 0
                            ? props.sitemapData.map((item, parentKey) => {
                                return (
                                    <Grid key={parentKey} className="col-md-12 Blog_Style">
                                        <div className="mt-md-4 mt-3 sup_site_map2 border p-3">
                                            <h2>{item.title}</h2>
                                            <Divider className="site-map-hr" />
                                            <ul className="list-group">
                                                {item.submenu !== undefined && Object.keys(item.submenu).length != 0 ? (item.submenu.map((submenuData, subKey) => {
                                                    return (
                                                        <Fragment key={subKey}>
                                                            {submenuData.url !== undefined && submenuData.url !== null ? (
                                                                <li  className="list-group-item " >
                                                                    <Link to={{ pathname: submenuData.url }} target="_blank">{submenuData.title}</Link>
                                                                </li>
                                                            ) : (
                                                                    <li className="list-group-item">
                                                                        <Link to={submenuData.slug}> {submenuData.title}</Link>
                                                                    </li>
                                                                )}
                                                        </Fragment>
                                                    );
                                                })
                                                ) : (
                                                        <>
                                                            {item.url !== undefined && item.url !== null ? (
                                                                <li className="list-group-item">
                                                                    <Link to={{ pathname: item.url }} target="_blank">{item.title}</Link>
                                                                </li>
                                                            ) : (
                                                                    <li className="list-group-item">
                                                                        <Link to={item.slug} >{item.title}</Link>
                                                                    </li>
                                                                )}
                                                        </>
                                                    )}
                                            </ul>
                                        </div>
                                    </Grid>
                                );
                            })
                            : ""}
                    </div>
                </div>
            </section>
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        sitemapData: state.sitemap,
        loading: state.app.loading
    };
};

export default connect(mapStateToProps, { loadSitemapAction })(withTranslation("common")(withRouter(SiteMap)));
