//default action
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const LOAD_HEADER_SUCCESSFUL = 'LOAD_HEADER_SUCCESSFUL';
export const LOAD_HOME_SUCCESSFUL = 'LOAD_HOME_SUCCESSFUL';
export const LOAD_ABOUT_SUCCESSFUL = 'LOAD_ABOUT_SUCCESSFUL';
export const LOAD_BLOG_SUCCESSFUL = 'LOAD_BLOG_SUCCESSFUL';
export const LOAD_GALLERY_IMAGE_SUCCESSFUL = 'LOAD_GALLERY_IMAGE_SUCCESSFUL';
export const LOAD_TEAM_SUCCESSFUL = 'LOAD_TEAM_SUCCESSFUL';
export const LOAD_SINGLE_BLOG_SUCCESSFUL = 'LOAD_SINGLE_BLOG_SUCCESSFUL';
export const LOAD_SERVICE_SUCCESSFUL = 'LOAD_SERVICE_SUCCESSFUL';
export const LOAD_PORTFOLIO_SUCCESSFUL = 'LOAD_PORTFOLIO_SUCCESSFUL';
export const LOAD_SINGLE_PORTFOLIO_SUCCESSFUL = 'LOAD_SINGLE_PORTFOLIO_SUCCESSFUL';
export const LOAD_PAGE_SUCCESSFUL = 'LOAD_PAGE_SUCCESSFUL';
export const LOAD_PRODUCT_SUCCESSFUL = 'LOAD_PRODUCT_SUCCESSFUL';
export const LOAD_STORE_SUCCESSFUL = 'LOAD_STORE_SUCCESSFUL';
export const LOAD_TENDER_SUCCESSFUL = 'LOAD_TENDER_SUCCESSFUL';
export const LOAD_CAREER_SUCCESSFUL = 'LOAD_CAREER_SUCCESSFUL';
export const LOAD_SITEMAP_SUCCESSFUL = 'LOAD_SITEMAP_SUCCESSFUL';
export const LOAD_SEARCH_SUCCESSFUL = 'LOAD_SEARCH_SUCCESSFUL';
export const LOAD_IMAGE_CATEGORY_SUCCESSFUL = 'LOAD_IMAGE_CATEGORY_SUCCESSFUL';
export const LOAD_GALLERY_VIDEO_SUCCESSFUL = 'LOAD_GALLERY_VIDEO_SUCCESSFUL';
export const LOAD_VIDEO_CATEGORY_SUCCESSFUL = 'LOAD_VIDEO_CATEGORY_SUCCESSFUL';
export const LOAD_LANGUAGE_SUCCESSFUL = 'LOAD_LANGUAGE_SUCCESSFUL';
export const LOAD_PRESS_RELEASE_SUCCESSFUL = 'LOAD_PRESS_RELEASE_SUCCESSFUL';
export const LOAD_TURN_OVER_SUCCESSFUL = 'LOAD_TURN_OVER_SUCCESSFUL';
export const LOAD_OUTLET_STRENGTH_SUCCESSFUL = 'LOAD_OUTLET_STRENGTH_SUCCESSFUL';
export const LOAD_VISITOR_SUCCESSFUL = 'LOAD_VISITOR_SUCCESSFUL';
export const LOAD_ANNOUNCEMENT_SUCCESSFUL = 'LOAD_ANNOUNCEMENT_SUCCESSFUL';
export const LOAD_HOD_SUCCESSFUL = 'LOAD_HOD_SUCCESSFUL';
export const LOAD_FETCH_COMPLAINT = 'LOAD_FETCH_COMPLAINT';