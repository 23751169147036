import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FontAwesome from "../UiStyle/FontAwesome";

import Logo from '../../images/logo.png';

import './style.scss';

const menulists = [
    {
        id: 1,
        name: 'home',
        link: '/home'
    },
    {
        id: 2,
        name: 'page',
        submenu: [
            {
                id: 1,
                name: 'Gallery',
                link: '/gallery'
            },
            {
                id: 2,
                name: 'blog details',
                link: '/blog-details/1'
            },
            {
                id: 3,
                name: 'portfolio single',
                link: '/portfolio-single'
            },
        ]
    },
    {
        id: 3,
        name: 'about us',
        link: '/about-us'
    },
    {
        id: 4,
        name: 'services',
        link: '/services'
    },
    {
        id: 5,
        name: 'team',
        link: '/team'
    },
    {
        id: 6,
        name: 'portfolio',
        link: '/portfolio'
    },
    {
        id: 7,
        name: 'blog',
        link: '/blog'
    },
    {
        id: 8,
        name: 'contact-us',
        link: '/contact-us'
    },
];

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));


const MobileMenu = ({ showMobile, setShowMobile, menus }) => {
    const classes = useStyles();

    const [menuId, setActiveMid] = useState();

    function handleExpandClick(id) {
        setActiveMid(id);
        if (menuId === id) {
            setActiveMid()
        }
    }

    return (
        <Fragment>
            <Grid className={`mobileMenu ${showMobile ? '' : 'hidden'}`}>
                <Grid className="sLogo">
                    <Link to="/"><img src={Logo} alt="logo" /></Link>
                </Grid>
                <List>
                    {menus != undefined ? menus.map((item, i) => {
                        return (
                            <ListItem key={item.id}>
                                {!item.submenu ?
                                    (item.component == "Page" && item.page !== undefined && item.page !== null && item.page.content_file != "") ?
                                        <Link to={{ pathname: item.page.content_file }} download target="_blank">{item.title}</Link>
                                        :
                                        <Link to={{ pathname: !!(item.url) ? item.url : ((item.component == "Page") ? `/cms/${item.slug}` : `/${item.slug}`) }} target={!!(item.url) ? '_blank' : '_self'}>{item.title}</Link>
                                    /****   section*****/
                                    : <Grid
                                        className="mItem"
                                        onClick={() => handleExpandClick(item.id)}
                                    >
                                        <span>{item.title}</span>
                                        <Grid
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: menuId === item.id,
                                            })}
                                        >
                                            <FontAwesome name="angle-down" />
                                        </Grid>
                                    </Grid>
                                }
                                {item.submenu ? item.submenu.map(subitem => {
                                    return (
                                        <Collapse key={subitem.id} className="subMenu" in={menuId === item.id} timeout="auto" unmountOnExit>
                                            {subitem.component == "Page" && subitem.page !== undefined && subitem.page !== null && subitem.page.content_file != "" ?
                                                <Link onClick={() => setShowMobile(!showMobile)} to={{ pathname: subitem.page.content_file }} download target="_blank">{subitem.title}</Link>
                                                :
                                                <Link onClick={() => setShowMobile(!showMobile)} to={{ pathname: !!(subitem.url) ? subitem.url : ((subitem.component == "Page") ? `/cms/${subitem.slug}` : `/${subitem.slug}`) }} target={!!(subitem.url) ? '_blank' : '_self'}>{subitem.title}</Link>
                                            }
                                        </Collapse>
                                    )
                                }) : ''}
                            </ListItem>
                        )
                    }) : ""}
                </List>
            </Grid>
            <Grid onClick={() => setShowMobile(!showMobile)} className={`menuHoverlay ${showMobile ? 'show' : ''}`}></Grid>
        </Fragment>
    )
};

export default MobileMenu;