import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import { Link, NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import MobileMenu from "../MobileMenu";
import Hidden from "@material-ui/core/Hidden";
import Logo from "../Logo";

import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from "react-i18next";

import logo from '../../images/logo.png';

import { loadHomeAction, loadHeaderAction, loadLanguageAction } from "../../store/actions/action";

import './style.scss';

function chunk(array, size) {
    const chunked_arr = [];
    let copied = [...array];
    const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
    for (let i = 0; i < numOfChild; i++) {
        chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
}

const subMenu = (menus) => {
    if (menus.length > 7) {
        let menuChunks = chunk(menus, 7);

        return (
            <ul className="dropdown-menu mega-dropdown-menu" aria-labelledby="menu-2">
                <li className="row">
                    {menuChunks.map((item, key) => {

                        return (
                            <ul className="col" key={key}>
                                {item.map((dataItem) => {
                                    return (
                                        <li key={'subitem-' + dataItem.id}>
                                            {(dataItem.component == "Page" && dataItem.page !== undefined && dataItem.page !== null && dataItem.page.content_file != "") ?
                                                <Link className="dropdown-item" to={{ pathname: dataItem.page.content_file }} download target="_blank">{dataItem.title}</Link>
                                                :
                                                <Link className="dropdown-item" to={{ pathname: !!(dataItem.url) ? dataItem.url : ((dataItem.component == "Page") ? `/cms/${dataItem.slug}` : `/${dataItem.slug}`) }} target={!!(dataItem.url) ? '_blank' : '_self'}>{dataItem.title}</Link>
                                            }</li>
                                    )
                                })}
                            </ul>
                        )
                    })}
                </li>
            </ul>
        )
    } else {
        return (
            <ul className="dropdown-menu">
                {
                    menus.map(subitem => {
                        return (
                            <li key={'subitem-' + subitem.id}>
                                {(subitem.component == "Page" && subitem.page !== undefined && subitem.page !== null && subitem.page.content_file !== "") ?
                                    <Link className="dropdown-item" to={{ pathname: subitem.page.content_file }} download target="_blank">{subitem.title}</Link>
                                    :
                                    <Link className="dropdown-item" to={{ pathname: !!(subitem.url) ? subitem.url : ((subitem.component == "Page") ? `/cms/${subitem.slug}` : `/${subitem.slug}`) }} target={!!(subitem.url) ? '_blank' : '_self'}>{subitem.title}</Link>
                                }
                            </li>
                        )
                    })
                }
            </ul >
        )
    }
}

const Header = (props) => {
    const [showMobile, setShowMobile] = useState(false);
    const [headerFixed, setHeaderFixed] = useState("static")
    const [searchKey, setsearchKey] = useState("static");;

    useEffect(() => {

        let cookie = Cookies.get('lang');
        if (!cookie) {
            cookie = 'en';
            Cookies.set('lang', cookie);
            setLang(cookie);
        } else {
            setLang(Cookies.get('lang'));
        }

        props.loadLanguageAction(Cookies.get('lang'));

        //For set header fixed
        let isMounted = true;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > 20) {
                if (isMounted) {
                    setHeaderFixed("fixed");
                }
            } else {
                if (isMounted) {
                    setHeaderFixed("static");
                }
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
            isMounted = false;
        };
    }, []);
    const { t, i18n } = useTranslation();

    function handleClick(lang) {
        i18n.changeLanguage(lang);
        i18n.changeLanguage(lang);
    }

    const defaultLanguage = (props.default_language !== undefined) ? props.default_language : "en";
    const [lang, setLang] = React.useState(defaultLanguage);
    const handleChange = event => {
        i18n.changeLanguage(event.target.value);
        Cookies.set('lang', event.target.value);
        setLang(Cookies.get('lang'));

        let params = {
            "banner_limit": "all",
            "store_limit": 8,
            "ansment_limit": 10
        };
        props.loadHeaderAction();
        props.loadHomeAction(params);
        props.loadLanguageAction(Cookies.get('lang'));
    };
    const handleLangChange = (langCode) => {
        i18n.changeLanguage(langCode);
        Cookies.set('lang', langCode);
        setLang(Cookies.get('lang'));

        let params = {
            "banner_limit": 4,
            "store_limit": 8,
            "ansment_limit": 10
        };
        props.loadHeaderAction();
        props.loadHomeAction(params);
        props.loadLanguageAction(Cookies.get('lang'));
    }
    let languages = (props.languages !== undefined) ? props.languages : "";

    const staticHeader = [
        { name: "Home", link: '/', component: "HomePage" },
        { name: "OnlineShopping", link: '/our-products', component: "" },
        { name: "Supplier", link: '/cms/coming-soon', component: "" },
        { name: "PublicDistribution", link: '/cms/public-distribution', component: "" },
        { name: "Corporate", link: 'http://supplyco-admin.dev.ndz.co.in', component: "" }
    ];
    const changeHandler = event => {
        setsearchKey(event.target.value);
    };
    const searchHandler = (event) => {
        if (event.key === "Enter") {
            props.history.push(`/search/${event.target.value}`);
            document.getElementById('searchInput').value = '';
        }
    }

    return (
        <>
            <Grid>
                <AppBar position={headerFixed} elevation={0} className="appbar-header">
                    <Toolbar className="toolbar">
                        <div className="search toolbarTitle head-logo-title">
                            <Link to="/" className="logo_main_sup navbar-brand">
                                <img src={props.header.logo ? props.header.logo : logo} className={!props.fixHeader ? 'light img-fluid' : 'img-fluid'} alt="Logo" />
                            </Link>
                            <InputBase
                                id="searchInput"
                                placeholder={props.t('Header.SearchText') + "..."}
                                inputProps={{ 'aria-label': 'search' }}
                                classes={{
                                    root: "search-input head-search"
                                }}
                                onKeyDown={searchHandler}
                                onChange={changeHandler}
                                endAdornment={<Link to="#"
                                    onClick={() => {
                                        props.history.push(`/search/${searchKey}`);
                                        document.getElementById('searchInput').value = '';
                                    }}
                                ><div className="searchIcon"><SearchIcon /></div></Link>}
                            />
                            <Button color="primary" variant="outlined" className={`link ${props.location.pathname === '/site-map' ? 'active' : ''}`} >
                                <Link className="sitemap-link" to='/site-map'  >
                                    {props.t('SiteMap.Title')}
                                </Link>
                            </Button>
                            {languages != "" ? Object.keys(languages).map((item, i) => {
                                if (Cookies.get('lang') !== item) {
                                    return (
                                        <Button key={i} color="primary" variant="outlined" className={`link`} >
                                            <Link className="sitemap-link" value={item} to="#" onClick={() => handleLangChange(item)}  >
                                                {languages[item]}
                                            </Link>
                                        </Button>
                                    )
                                }
                            }) : ''}

                            {/* <Select value={lang} onChange={handleChange} displayEmpty className={"head-search languageSelect"}>
                                {languages != "" ? Object.keys(languages).map((item, i) => {
                                    return (
                                        <MenuItem value={item} key={i}>{languages[item]}</MenuItem>
                                    )
                                }) : ''}
                            </Select> */}
                        </div>
                        <div className="menu-right d-flex">
                            {staticHeader !== undefined ? staticHeader.map((item, i) => {
                                return (
                                    // (item.name == 'Corporate')
                                    <Link key={i} component="button" to={{ pathname: item.link }} color="primary" variant="outlined"
                                        className={`MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary link ${props.location.pathname === item.link ? 'active' : ''}`}
                                        target={item.name === "Corporate" ? '_blank' : ''} >
                                        {props.t('Header.' + item.name)}
                                    </Link>
                                )
                            }) : ''}
                        </div>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid className={`header ${!props.fixHeader ? 'fixedHeader' : ''}`}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className="menu-container"
                >
                    {/* <Grid item xs={9} md={2} lg={3}> */}
                    {/* <Logo
                            logo={props.header.logo ? props.header.logo : logo}
                            alt="Logo"
                            link="/"
                            isHeaderFix={!props.fixHeader}
                            className="logo_main_sup-two navbar-brand"
                        /> */}
                    {/* </Grid> */}
                    <Hidden mdDown>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid className="mainMenu">
                                <section className="header pr-0">
                                    <div className="main-nav">
                                        <nav className="navbar navbar-expand-lg navbar-classic px-0">
                                            <div className="collapse navbar-collapse" id="navbar-classic">
                                                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                                    {props.menus != undefined ? props.menus.map((item, i) => {

                                                        return (
                                                            <li key={item.id} className={!item.submenu ? "nav-item" : item.submenu.length > 7 ? "nav-item dropdown mega-dropdown" : "nav-item dropdown"}>
                                                                {!item.submenu

                                                                    ?
                                                                    (item.component == "Page" && item.page !== undefined && item.page !== null && item.page.content_file != "") ?
                                                                        <Link className={props.location.pathname === '/' + item.slug ? 'nav-link mr-0 active' : 'nav-link mr-0'}
                                                                            to={{ pathname: item.page.content_file }} download target="_blank">{item.title}</Link>
                                                                        :
                                                                        <Link
                                                                            className={'nav-link mr-0'}
                                                                            to={{ pathname: !!(item.url) ? item.url : ((item.component == "Page") ? `/cms/${item.slug}` : `/${item.slug}`) }}
                                                                            target={!!(item.url) ? '_blank' : '_self'}
                                                                        >
                                                                            {item.title}
                                                                        </Link>
                                                                    /****   section*****/
                                                                    :

                                                                    (item.component == "Page" && item.page !== undefined && item.page !== null && item.page.content_file != "") ?
                                                                        <Link className={props.location.pathname === '/' + item.slug ? 'nav-link dropdown-toggle mr-0 active' : 'nav-link dropdown-toggle mr-0'}
                                                                            to="#">{item.title}</Link>
                                                                        :

                                                                        <Link
                                                                            className={props.location.pathname === '/' + item.slug ? 'nav-link dropdown-toggle mr-0 active' : 'nav-link dropdown-toggle mr-0'}
                                                                            to="#"
                                                                        >
                                                                            {item.title}
                                                                        </Link>
                                                                }
                                                                {item.submenu ? (
                                                                    subMenu(item.submenu)
                                                                ) : null
                                                                }
                                                            </li>
                                                        )
                                                    }) : ""}
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </section>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden lgUp>
                        <Grid item xs={12} sm={12} className="text-right">
                            <Grid className={`humbergur ${showMobile ? 'show' : ''}`}
                                onClick={() => setShowMobile(!showMobile)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                <MobileMenu showMobile={showMobile} setShowMobile={setShowMobile} menus={props.menus} />
            </Grid>
        </>
    )
};

const mapStateToProps = state => {
    return {
        header: state.header,
        menus: state.header.menu_list,
        languages: state.header.languages,
        default_language: state.header.default_language
    }
};

export default connect(mapStateToProps, { loadHomeAction, loadHeaderAction, loadLanguageAction })(withTranslation('common')(withRouter(Header)));