import {
    LOAD_HOME_SUCCESSFUL,
    LOAD_ABOUT_SUCCESSFUL,
    LOADING,
    LOADED,
    LOAD_BLOG_SUCCESSFUL,
    LOAD_GALLERY_IMAGE_SUCCESSFUL,
    LOAD_TEAM_SUCCESSFUL,
    LOAD_SINGLE_BLOG_SUCCESSFUL,
    LOAD_SERVICE_SUCCESSFUL,
    LOAD_PORTFOLIO_SUCCESSFUL,
    LOAD_SINGLE_PORTFOLIO_SUCCESSFUL,
    LOAD_HEADER_SUCCESSFUL,
    LOAD_PAGE_SUCCESSFUL,
    LOAD_PRODUCT_SUCCESSFUL,
    LOAD_STORE_SUCCESSFUL,
    LOAD_IMAGE_CATEGORY_SUCCESSFUL,
    LOAD_VIDEO_CATEGORY_SUCCESSFUL,
    LOAD_GALLERY_VIDEO_SUCCESSFUL,
    LOAD_TENDER_SUCCESSFUL,
    LOAD_CAREER_SUCCESSFUL,
    LOAD_SITEMAP_SUCCESSFUL,
    LOAD_SEARCH_SUCCESSFUL,
    LOAD_LANGUAGE_SUCCESSFUL,
    LOAD_PRESS_RELEASE_SUCCESSFUL,
    LOAD_TURN_OVER_SUCCESSFUL,
    LOAD_OUTLET_STRENGTH_SUCCESSFUL,
    LOAD_VISITOR_SUCCESSFUL,
    LOAD_ANNOUNCEMENT_SUCCESSFUL,
    LOAD_HOD_SUCCESSFUL,
    LOAD_FETCH_COMPLAINT

} from "./type";
import { request } from "../../utils/request";
import Cookies from 'js-cookie'

export const loadHeaderAction = (data) => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`header?lang=${lang}`)
        .then(res => {
            dispatch({
                type: LOAD_HEADER_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadHomeAction = data => dispatch => {
    dispatch({ type: LOADING });
    if (data) {
        let lang = Cookies.get('lang');
        if (!lang) {
            lang = 'en';
        }
        let customUrl = `lang=${lang}&banner_limit=${data.banner_limit}&store_limit=${data.store_limit}&ansment_limit=${data.ansment_limit}`;
        request(`home?${customUrl}`)
            .then(res => {
                dispatch({
                    type: LOAD_HOME_SUCCESSFUL,
                    data: res.data,
                });
                dispatch({ type: LOADED });
            })
            .catch(error => {
                dispatch({ type: LOADED, data: error });
            });
    } else {
        request('home')
            .then(res => {
                dispatch({
                    type: LOAD_HOME_SUCCESSFUL,
                    data: res.data,
                });
                dispatch({ type: LOADED });
            })
            .catch(error => {
                dispatch({ type: LOADED, data: error });
            });
    }
};

export const loadVisitCountAction = (device_type) => dispatch => {
    dispatch({ type: LOADING });
    request(`home/visitor-count`)
        .then(res => {
            dispatch({
                type: LOAD_VISITOR_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
            Cookies.set('visitorCount', res.data, { expires: 1 });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadAboutAction = data => dispatch => {
    dispatch({ type: LOADING });
    request('about-us')
        .then(res => {
            dispatch({
                type: LOAD_ABOUT_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadBlogAction = data => dispatch => {
    dispatch({ type: LOADING });
    if (data) {
        request(`blogs?cat=${data}`)
            .then(res => {
                dispatch({
                    type: LOAD_BLOG_SUCCESSFUL,
                    data: res.data,
                });
                dispatch({ type: LOADED });
            })
            .catch(error => {
                dispatch({ type: LOADED, data: error });
            });
    } else {
        request('blogs')
            .then(res => {
                dispatch({
                    type: LOAD_BLOG_SUCCESSFUL,
                    data: res.data,
                });
                dispatch({ type: LOADED });
            })
            .catch(error => {
                dispatch({ type: LOADED, data: error });
            });
    }
};

export const loadGalleryAction = data => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`gallery${data.type}?lang=${lang}&category_id=${data.category_id}`)
        .then(res => {
            if (data.type == "/image") {
                dispatch({
                    type: LOAD_GALLERY_IMAGE_SUCCESSFUL,
                    data: res.data,
                });
            } else if (data.type == "/video") {
                dispatch({
                    type: LOAD_GALLERY_VIDEO_SUCCESSFUL,
                    data: res.data,
                });
            }
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadTeamAction = data => dispatch => {
    dispatch({ type: LOADING });
    request('teams')
        .then(res => {
            dispatch({
                type: LOAD_TEAM_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadSingleBlogAction = data => dispatch => {
    dispatch({ type: LOADING });
    request(`blogs/${data}`)
        .then(res => {
            dispatch({
                type: LOAD_SINGLE_BLOG_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadServiceAction = data => dispatch => {
    dispatch({ type: LOADING });
    request('service')
        .then(res => {
            dispatch({
                type: LOAD_SERVICE_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadPortfolioAction = data => dispatch => {
    dispatch({ type: LOADING });
    request('portfolio')
        .then(res => {
            dispatch({
                type: LOAD_PORTFOLIO_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadSinglePortfolioAction = data => dispatch => {
    dispatch({ type: LOADING });
    request(`portfolio/${data}`)
        .then(res => {
            dispatch({
                type: LOAD_SINGLE_PORTFOLIO_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadingAction = () => dispatch => {
    dispatch({
        type: LOADING,
    });
};

export const loadedAction = () => dispatch => {
    dispatch({
        type: LOADED,
    });
};

export const loadPageAction = data => dispatch => {
    let lang = Cookies.get('lang');
    request(`page/${data}?lang=${lang}`)
        .then(res => {
            dispatch({
                type: LOAD_PAGE_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadProductAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}&product_limit=${data.product_limit}&page=${data.page}&product_id=${data.product_id}`;
    request(`product/list?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_PRODUCT_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadStoreAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}&store_category_id=${data.store_category_id}&store_limit=${data.store_limit}&page=${data.page}`;
    customUrl += (data.sort !== "") ? `&sortColumn=${data.sort.field}&sortDirection=${data.sort.direction}` : "";
    request(`store/list?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_STORE_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};


export const loadGalleryCategoryAction = data => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`gallery${data}/category?lang=${lang}`)
        .then(res => {
            if (data == "/image") {
                dispatch({
                    type: LOAD_IMAGE_CATEGORY_SUCCESSFUL,
                    data: res.data,
                });
            } else if (data == "/video") {
                dispatch({
                    type: LOAD_VIDEO_CATEGORY_SUCCESSFUL,
                    data: res.data,
                });
            }

            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadTenderAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}&tender_limit=${data.tender_limit}&page=${data.page}`;
    customUrl += (data.sort !== "") ? `&sortColumn=${data.sort.field}&sortDirection=${data.sort.direction}` : "";
    request(`etender/list?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_TENDER_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadCareerAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}&career_limit=${data.career_limit}&page=${data.page}`;
    customUrl += (data.sort !== "") ? `&sortColumn=${data.sort.field}&sortDirection=${data.sort.direction}` : "";
    request(`career/list?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_CAREER_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadSitemapAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}`;
    request(`home/sitemap?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_SITEMAP_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadSearchAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}&key=${data.searchKey}`;
    request(`global/search?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_SEARCH_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadPressReleaseAction = data => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`press/release/list?lang=${lang}`)
        .then(res => {
            dispatch({
                type: LOAD_PRESS_RELEASE_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadLanguageAction = data => dispatch => {
    dispatch({
        type: LOAD_LANGUAGE_SUCCESSFUL,
        data: data,
    });
};

export const loadSalesTurnOverAction = data => dispatch => {
    dispatch({ type: LOADING });
    request(`turnover/list`)
        .then(res => {
            dispatch({
                type: LOAD_TURN_OVER_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadOutletStrengthAction = data => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`store/outlet-strength?lang=${lang}`)
        .then(res => {
            dispatch({
                type: LOAD_OUTLET_STRENGTH_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadAnnouncementAction = data => dispatch => {
    dispatch({ type: LOADING });
    let lang = Cookies.get('lang');
    request(`announcement/list?lang=${lang}&ansment_limit=all`)
        .then(res => {
            dispatch({
                type: LOAD_ANNOUNCEMENT_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};

export const loadHoDivisions = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}`;
    request(`ho-division/list?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_HOD_SUCCESSFUL,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};
export const loadFetchComplaintAction = data => dispatch => {
    let lang = Cookies.get('lang');
    let customUrl = `lang=${lang}`;
    request(`complaints/data?${customUrl}`)
        .then(res => {
            dispatch({
                type: LOAD_FETCH_COMPLAINT,
                data: res.data,
            });
            dispatch({ type: LOADED });
        })
        .catch(error => {
            dispatch({ type: LOADED, data: error });
        });
};