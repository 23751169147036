import React, { Fragment, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import ListEtender from "../../components/ListEtender";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import { loadTenderAction } from "../../store/actions/action";

class EtenderList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage />
                <ListEtender />
                <Footer />
            </Fragment >
        )
    }
};
const mapStateToProps = state => {
    return {
        etender: state.tender
    }
};
export default connect(mapStateToProps, { loadTenderAction })(EtenderList);