import React, {Fragment,Component} from 'react';
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ListImage from "../../components/ListImage";
import {loadGalleryAction} from "../../store/actions/action";
import Cookies from 'js-cookie'
class GalleryList extends Component {
    state = {
        lang: ''
    };
    componentDidMount() {
        const type = "/image";
        let category_id = this.props.match.params.id;
        const data = {type,category_id};
        this.props.loadGalleryAction(data);
        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            const type = "/image";
            let category_id = props.match.params.id;
            const data = {type,category_id};
            props.loadGalleryAction(data);
        }

        return true;
    }

    render(){
        return (
            <Fragment>
                <SubImage/>
                <ListImage />
                <Footer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        home: state.home,
        page: state.page
    }
};

export default connect(mapStateToProps,{loadGalleryAction})(withRouter(GalleryList));