import React, { Fragment, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import ListCareer from "../../components/ListCareer";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import { loadCareerAction } from "../../store/actions/action";

class CareerList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage />
                <ListCareer />
                <Footer />
            </Fragment >
        )
    }
};
const mapStateToProps = state => {
    return {
        careers: state.career
    }
};
export default connect(mapStateToProps, { loadCareerAction })(CareerList);