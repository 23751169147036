import React, { Fragment } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import TurnOverGraph from "../../components/TurnOverGraph";
import {loadSalesTurnOverAction} from "../../store/actions/action";
import SubImage from "../../components/SubImage";

class SalesTurnOver extends React.Component {

    componentDidMount() {
        if(Object.keys(this.props.turn_over).length === 0){
            this.props.loadSalesTurnOverAction()
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage/>
                <TurnOverGraph/>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        turn_over : state.sales_turn_over
    };
};

export default connect(mapStateToProps, { loadSalesTurnOverAction })(
    SalesTurnOver
);
