import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader';
import "./style.scss";

const getPressReleaseDetails = (press_release, id) => {
    let press_release_details = [];

    if (press_release !== undefined && press_release.length>0)
        press_release_details = press_release.filter(release => release.id == id);
    return press_release_details[0];
};

const DetailsPressRelease = props => {

    let press_release_details = getPressReleaseDetails(props.press_release, atob(props.props.params.id));

    return (
        <>
            {props.loading && <Loader />}
            {!props.loading &&press_release_details !== undefined ? (
                <Grid className="mt-md-5 mb-md-5 mt-3 mb-3">
                    <Grid className="container release-details" container spacing={4}>
                        <Grid item xs={12}>
                            <Grid className="prtdRightContent">
                                <h2>{press_release_details && press_release_details.title}</h2>
                                <Divider />
                            </Grid>
                            <Grid className="prtdRightContent">
                                <p>{press_release_details && press_release_details.description}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                    ""
                )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        press_release : state.press_release,
        loading: state.app.loading
    };
};

export default connect(mapStateToProps)(withTranslation("common")(DetailsPressRelease));
