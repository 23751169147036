import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import PressReleaseSection from "../../components/PressRelease";
import Footer from "../../components/Footer";
import {loadPressReleaseAction} from "../../store/actions/action";
import SubImage from "../../components/SubImage";
import Cookies from 'js-cookie'

class PressRelease extends Component {
    state = {
        lang: ''
    };
    componentDidMount() {
        this.props.loadPressReleaseAction()
        this.setState({
            lang: Cookies.get('lang')
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            props.loadPressReleaseAction()
        }

        return true;
    }
    render(){
        return (
            <Fragment>
                <SubImage/>
                <PressReleaseSection/>
                <Footer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        press_relase : state.press_release
    }
};

export default connect(mapStateToProps, {loadPressReleaseAction})(PressRelease);