import React, { Fragment, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import SiteMap from "../../components/SiteMap";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import { loadSitemapAction } from "../../store/actions/action";

class SiteMapList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage />
                <SiteMap />
                <Footer />
            </Fragment >
        )
    }
};
const mapStateToProps = state => {
    return {
        sitemapData: state.sitemap,
    }
};
export default connect(mapStateToProps, { loadSitemapAction })(SiteMapList);