import React from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './style.scss';

const ComplaintFormDailog = (props) => {
    const [open, setOpen] = React.useState(props.openStatus);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgreeStatus = () => {
        if (open === false) {
            handleClickOpen();
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="no-click-backdrop"
            >
                <DialogTitle id="alert-dialog-title" className="complaint-popup-title">  {props.t('Complaint.Dialog_content_Title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.t('Complaint.Dialog_content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                        Disagree
                        </Button> */}
                    <Button onClick={handleClose} color="primary" className="agreement-btn" >
                        {props.t('Complaint.agree-btn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        loading: state.app.loading
    }
};

export default connect(mapStateToProps)(withTranslation('common')(withRouter(ComplaintFormDailog)));
