import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import GalleryImageCategory from "../../components/GalleryImageCategory";
import {withRouter} from 'react-router-dom';
import Cookies from 'js-cookie'

import {loadGalleryCategoryAction} from "../../store/actions/action";
class Gallery extends Component {
    state = {
        lang: ''
    };

    componentDidMount() {
        const type = "/image";
        this.props.loadGalleryCategoryAction(type);
        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            const type = "/image";
            props.loadGalleryCategoryAction(type);
        }

        return true;
    }

    render(){
        return (
            <Fragment>
                {/* <BreadCrumb
                    pagename="Creative Protfolio"
                    title={props.gallery.gallery_banner_title}
                />
                <PortfolioMasonary image_list={props.gallery.image_list}/>
                <WorkInquiry workInquiry={props.gallery} className="hasMb"/> */}
                <SubImage/>
                <GalleryImageCategory/>
                <Footer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        imageCategories: state.gallery.imageCategory
    }
};

export default connect(mapStateToProps, {loadGalleryCategoryAction})(withRouter(Gallery));