import 'date-fns';
import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux'

import { loadFetchComplaintAction } from "../../store/actions/action";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './style.scss';
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import moment from "moment";

import ComplaintDocumentsList from "../ComplaintDocumentsList"
import ComplaintFormDailog from "../ComplaintFormDailog"
import Joi from 'joi-browser';
import { postRequest } from "../../utils/request";

import googleTransliterate from 'google-input-tool';
import Loader from '../Loader';
import swal from 'sweetalert';

import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";

class ComplaintFormDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: '',
            error: {},
            doc_upload_err: true,
            doc_upload_data_err: true,
            form_loader: false,
            doc_file_img: true,
            doc_file_doc: true,
            doc_file_type: true,
            data: {
                applicant_name: '',
                name_english: '',
                complaint_email: '',
                phone: '',
                message: '',
                subject: -1,
                house_name: '',
                house_no: '',
                pincode: '',
                street: '',
                documents: [],
                document_name: '',
                document_type: "document",
                document_file: '',
                complaint_message: '',
                selectedDate: new Date(),
                complaint_type: "",
                taluk: -1,
                district: -1,
                complaint_district: -1,
                department: '',
                outlet: -1,
                aadhar: '',
                reference: '',
                reference_district: -1,
                reference_office: -1,
                gcaptcha_code: '',
                doc_upload_name: true,
            }

        };
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.markItemCompleted = this.markItemCompleted.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
    }

    componentDidMount() {
        this.fetchData();
        this.setState({
            lang: Cookies.get('lang')
        })
    }
    componentWillUpdate() {
        if (this.props.selectedLanguage != "" && this.state.lang != "" && this.props.selectedLanguage !== this.state.lang) {
            this.state.lang = this.props.selectedLanguage;
            const error = this.validate();
            this.setState({
                error: error || {},
            });
        }
    }
    fetchData = async () => {
        this.props.loadFetchComplaintAction();
    }
    handleTextTranslation = (event, field_name) => {
        let opVal = event.target.value;
        let data = { ...this.state.data };
        data[field_name] = opVal;
        this.setState({
            data
        });
        if (field_name === "message" || field_name === "complaint_message") {
            this.handleWordCount(field_name, opVal);
        }
        const error = this.validate();
        this.setState({
            error: error || {},
        });
    }
    handleTextWordCount = (event) => {
        if (event.target.name === "message" || event.target.name === "complaint_message") {
            this.handleWordCount(event.target.name, event.target.value);
        }
    }
    handleWordCount = (event_name, val, process = "") => {
        if (val.length) {
            let count = (val.length > 0) ? val.length + 1 : 0;
            const error = { ...this.state.error };
            if (count > 3000 && event_name === "complaint_message") {
                error[event_name] = this.props.t('Complaint.msg_limitt_err');
                if (process === "submit") return true;
            } else if (count > 500 && event_name === "message") {
                error[event_name] = this.props.t('Complaint.msg_limitt_err');
                if (process === "submit") return true;
            }
            else {
                delete error[event_name];
            }
            this.setState({ error });
        }
    }
    /************************************ */
    onChange = (value) => {
        let data = { ...this.state.data };
        data['gcaptcha_code'] = value;
        this.setState({
            data
        });
    };
    handleFileType = (event) => {
        let data = { ...this.state.data };

        data['doc_upload_name'] = true;
        data['document_file'] = '';
        data[event.target.name] = event.target.value;

        this.setState({
            data, doc_upload_err: true,
        });
    }
    allowedFileTypes = (fileType) => {
        let imageValues = [
            "image/jpeg", "image/png", "image/jpg"
        ];
        let docValues = ["application/msword", "application/pdf"]
        if (!imageValues.includes(fileType) && this.state.data.document_type === "image") {
            this.setState({
                doc_file_img: false,
                doc_file_doc: true
            })

        } else if (!docValues.includes(fileType) && this.state.data.document_type === "document") {
            this.setState({
                doc_file_img: true,
                doc_file_doc: false
            })
        } else {
            this.setState({
                doc_file_img: true,
                doc_file_doc: true
            })
        }
    }
    // Handles file upload event and updates state
    handleUpload = (event) => {
        let data = { ...this.state.data };
        let File_type = "";
        if (event.target.files[0]) {
            File_type = event.target.files[0].type;
        }
        data['document_file'] = '';
        this.setState({
            doc_file_type: true,
        })
        this.allowedFileTypes(File_type)
        let resData = this.state.data;
        if (resData.document_type === "image" || resData.document_type === "document") {
            if (resData.document_type === "image" && (File_type == "image/jpeg" || File_type == "image/png" || File_type == "image/jpg")) {
                if (event.target.files[0].size <= 204800) {

                    data['doc_upload_name'] = false;
                    data['document_file'] = event.target.files[0];
                    this.setState({
                        doc_upload_err: true,
                        doc_upload_data_err: true
                    })

                } else {
                    data['doc_upload_name'] = true;
                    data['document_file'] = "";
                    this.setState({
                        doc_upload_err: false,
                        doc_upload_data_err: true,
                    })

                }
            }
            // else if(this.state.document_type==="document" && (File_type==="application/msword"|| File_type==="application/pdf"||File_type==="application/doc"||File_type==="application/docx"||File_type==="application/odt"||File_type==="application/txt"||File_type==="application/rtf")){
            else if (resData.document_type === "document" && (File_type === "application/pdf" || File_type === "application/msword")) {
                if (event.target.files[0].size <= 1048576) {
                    data['doc_upload_name'] = false;
                    data['document_file'] = event.target.files[0];
                    this.setState({
                        doc_upload_err: true,
                        doc_upload_data_err: true,
                    })

                } else {
                    data['doc_upload_name'] = true;
                    data['document_file'] = "";
                    this.setState({
                        doc_upload_err: false,
                        doc_upload_data_err: false,
                    })

                }

            }
        } else {
            this.setState({
                doc_file_type: false,
            })
        }
        this.setState({
            data
        })
    };
    handleTextChange(event) {
        let data = { ...this.state.data };
        data[event.target.name] = event.target.value;
        this.setState({
            data
        });
    }
    handleAddItem(event) {
        event.preventDefault();
        let data = { ...this.state.data };
        if (!data.document_name || !data.document_type || !data.document_file) {
            this.setState({
                doc_upload_data_err: false,
            });
        }
        else {

            let newItem = {
                id: Date.now(),
                document_name: this.state.data.document_name,
                document_type: this.state.data.document_type,
                document_file: this.state.data.document_file,
                done: false
            };
            data['documents'] = [...this.state.data.documents, newItem];
            data['document_file'] = "";
            data['document_name'] = "";
            data['document_type'] = "document";
            this.setState({
                data,
                doc_upload_data_err: true,
            });

        }
    }
    markItemCompleted(itemId) {
        var updatedItems = this.state.data.documents.map(item => {
            if (itemId === item.id)
                item.done = !item.done;
            return item;
        });
        // State Updates are Merged
        let data = { ...this.state.data };
        data['documents'] = [].concat(updatedItems)
        this.setState({
            data
        });
    }
    handleDeleteItem(itemId) {
        var updatedItems = this.state.data.documents.filter(item => {
            return item.id !== itemId;
        });

        let data = { ...this.state.data };
        data['documents'] = [].concat(updatedItems)
        this.setState({
            data
        });
    }
    schema = {
        applicant_name: Joi.string()
            .required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'any.empty':
                            err.message = this.props.t('Complaint.name-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        house_name: Joi.string()
            .required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'any.empty':
                            err.message = this.props.t('Complaint.house-name-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        house_no: Joi.string()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'any.empty':
                            err.message = this.props.t('Complaint.house-no-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            })
            .label("House No"),
        pincode: Joi.number().required()
            .min(100000)
            .max(999999)
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.pincode-error')
                            break;
                        case 'number.min':
                            err.message = this.props.t('Complaint.pincode-error1')
                            break;
                        case 'number.max':
                            err.message = this.props.t('Complaint.pincode-error1')
                            break;
                        case 'any.required':
                            err.message = this.props.t('Complaint.pincode-error2')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            })
            .label("pincode"),
        aadhar: Joi.number().allow("")
            .min(100000000000)
            .max(999999999999)
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.aadhar-error')
                            break;
                        case 'number.min':
                            err.message = this.props.t('Complaint.aadhar-error1')
                            break;
                        case 'number.max':
                            err.message = this.props.t('Complaint.aadhar-error1')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            })
            .label("aadhar"),
        street: Joi.string()
            .required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'any.empty':
                            err.message = this.props.t('Complaint.street-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        district: Joi.number().required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.district-error')
                            break;
                        case 'any.empty':
                            err.message = this.props.t('Complaint.district-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        taluk: Joi.number().required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.Taluk-error')
                            break;
                        case 'any.empty':
                            err.message = this.props.t('Complaint.Taluk-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        complaint_district: Joi.number().required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.district-error')
                            break;
                        case 'any.empty':
                            err.message = this.props.t('Complaint.district-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        outlet: Joi.number().required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.office-error')
                            break;
                        case 'any.empty':
                            err.message = this.props.t('Complaint.office-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        phone: Joi.number()
            .min(1000000000)
            .max(9999999999)
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'number.base':
                            err.message = this.props.t('Complaint.phone-error')
                            break;
                        case 'number.min':
                            err.message = this.props.t('Complaint.phone-error1')
                            break;
                        case 'number.max':
                            err.message = this.props.t('Complaint.phone-error1')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            })
            .label("Phone"),
        subject: Joi.string().required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'any.empty':
                            err.message = this.props.t('Complaint.subject-error')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        complaint_email: Joi.string().allow("")
            .email()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'string.email':
                            err.message = this.props.t('Complaint.email-val1')
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
        message: Joi.string().allow("").label("message"),
        complaint_message: Joi.string()
            .required()
            .error(errors => ({
                message: this.props.t('Complaint.complaint-message-error'),
            })),
        complaint_type: Joi.string().required()
            .error(errors => ({
                message: this.props.t('Complaint.complaint-type-error'),
            })),
    };

    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        if (event.target.name === "complaint_email") {
            if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(event.target.value)) {
                error[event.target.name] = this.props.t('Complaint.email-val1');
            }
        }
        if (event.target.name === "message" || event.target.name === "complaint_message") {
            this.handleWordCount(event.target.name, event.target.value);
        }
        let data = { ...this.state.data };
        data[event.target.name] = event.target.value;
        this.setState({
            data,
            error,
        });
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] };
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        let district_val = "";
        let taluk_val = "";
        let complaint_district_val = "";
        let outlet_val = "";
        let subject_val = "";
        let resData = { ...this.state.data };
        if (resData.district !== -1) district_val = resData.district;
        if (resData.taluk !== -1) taluk_val = resData.taluk;
        if (resData.complaint_district !== -1) complaint_district_val = resData.complaint_district;
        if (resData.subject !== -1) subject_val = resData.subject;

        if (resData.outlet !== -1) outlet_val = resData.outlet;
        const form = {
            complaint_email: resData.complaint_email,
            phone: resData.phone,
            subject: subject_val,
            message: resData.message,
            complaint_message: resData.complaint_message,
            applicant_name: resData.applicant_name,
            house_name: resData.house_name,
            house_no: resData.house_no,
            street: resData.street,
            district: district_val,
            taluk: taluk_val,
            complaint_district: complaint_district_val,
            outlet: outlet_val,
            complaint_type: resData.complaint_type,
            pincode: resData.pincode,
            aadhar: resData.aadhar
        };

        const { error } = Joi.validate(form, this.schema, options);
        let complaint_message_err = this.handleWordCount("complaint_message", resData.complaint_message, "submit");
        let message_err = this.handleWordCount("message", resData.message, "submit");
        if (!error && !message_err && !complaint_message_err) return null;
        if (!error) return null;
        const errors = {};
        for (const item of error.details) errors[item.path[0]] = item.message;
        if (message_err)
            errors["message"] = this.props.t('Complaint.msg_limitt_err')
        if (complaint_message_err)
            errors["complaint_message"] = this.props.t('Complaint.msg_limitt_err')
        return errors;
    };

    submitHandler = event => {
        event.preventDefault();
        const error = this.validate();
        this.setState({
            error: error || {},
        });
        let complaint_date = moment(this.state.data.selectedDate).format('DD/MM/YYYY');
        let reference_district_val = "";
        let reference_office_val = "";
        let resData = { ...this.state.data };
        if (resData.reference_office !== -1) reference_office_val = resData.reference_office;
        if (resData.reference_district !== -1) reference_district_val = resData.reference_district;

        let formData = new FormData();
        formData.append("lang", this.state.lang);
        formData.append("name", resData.applicant_name);
        formData.append("applicant_name", resData.name_english);
        formData.append("complaint_email", resData.complaint_email);
        formData.append("phone", resData.phone);
        formData.append("house_name", resData.house_name);
        formData.append("house_no", resData.house_no);
        formData.append("pincode", resData.pincode);
        formData.append("street", resData.street);
        formData.append("complaint_message", resData.complaint_message);
        formData.append("complaint_date", complaint_date);
        formData.append("complaint_type", resData.complaint_type);
        formData.append("taluk", resData.taluk);
        formData.append("district", resData.district);
        formData.append("department", resData.department);
        formData.append("outlet", resData.outlet);
        formData.append("aadhar", resData.aadhar);
        formData.append("reference", resData.reference);
        formData.append("reference_office", reference_office_val);
        formData.append("reference_comments", resData.message);
        formData.append("subject", resData.subject);
        formData.append("complaint_district", resData.complaint_district);
        formData.append("reference_district", reference_district_val);
        formData.append("g-recaptcha-response", resData.gcaptcha_code);
        resData.documents.map((item, index) => {
            formData.append(`document_file[${index}]`, item.document_file);
            formData.append(`document_name[${index}]`, item.document_name);
            formData.append(`document_type[${index}]`, item.document_type);
        })
        if (resData.document_file !== '') {
            let index = (resData.documents.length > 0) ? resData.documents.length : 0;
            formData.append(`document_file[${index}]`, resData.document_file);
            formData.append(`document_name[${index}]`, resData.document_name);
            formData.append(`document_type[${index}]`, resData.document_type);
        }

        if (!error && resData.gcaptcha_code !== '') {
            this.setState({ form_loader: true })
            postRequest('complaints/save', formData)
                .then(res => {
                    this.setState({ form_loader: false })
                    if (res.data.success === true) {
                        // toast.success(res.data.message);
                        swal({
                            title: res.data.message,
                            text: '',
                            icon: 'success',
                            button: 'OK'
                        });

                        this.setState({
                            data: {
                                name_english: '',
                                complaint_email: '',
                                phone: '',
                                subject: -1,
                                message: '',
                                applicant_name: '',
                                house_name: '',
                                house_no: '',
                                pincode: '',
                                street: '',
                                documents: [],
                                document_name: '',
                                document_type: 'document',
                                document_file: '',
                                complaint_message: '',
                                selectedDate: new Date(),
                                complaint_type: "",
                                taluk: -1,
                                district: -1,
                                complaint_district: -1,
                                department: '',
                                outlet: -1,
                                aadhar: '',
                                reference: '',
                                reference_district: -1,
                                reference_office: -1,
                                gcaptcha_code: '',
                                doc_upload_err: true,
                                doc_upload_name: true,
                            }
                        })
                    } else {
                        toast.error(res.data.message);
                        res.data.error_message.length > 0 &&
                            res.data.error_message.map((item, index) => {
                                toast.error(item);
                            })
                    }
                })
                .catch(error => {
                    this.setState({ form_loader: false })
                    console.log(error);
                    toast.error('Server error!');
                });
        }
    };
    handleDateChange = (date) => {
        let data = { ...this.state.data };
        data['selectedDate'] = date;
        this.setState({
            data
        });
    };
    render() {

        const { applicant_name, name_english, aadhar, house_name, house_no, pincode, outlet, reference_office,
            street, complaint_email, message, subject, phone, taluk, district, reference,
            selectedDate, complaint_message, complaint_type, complaint_district, reference_district } = this.state.data;
        const { className = '' } = this.props;
        const { lang } = this.state;

        return (
            <Grid className={`complainFormWrapper ${className}`}>
                {this.props.complaint_data !== "undefined" ?
                    <ComplaintFormDailog openStatus={true}></ComplaintFormDailog>
                    : ""
                }
                <Grid
                    className="container"
                    container
                    spacing={4}>
                    <Grid item xs={12} md={2}>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Grid className="contactRight">
                            <Grid className="textContent">
                                <div className="row h-md-100">
                                    <div className=" col-2 col-md-3 col-lg-2 text-left">
                                    </div>
                                    <div className="col-8 col-md-6 col-lg-8 text-center">
                                        <h2 className="complaint-head-title">{this.props.t('Complaint.Title')} </h2>
                                        <p className="kscsc-form">{this.props.t('Home.KSCSCCommonSection.kscsc')}</p>
                                        <p className="kscsc-form">{this.props.t('Complaint.SubTitle6')}</p>
                                    </div>
                                    <div className="col-2  col-md-3 col-lg-2 text-right">
                                    </div>
                                </div>
                            </Grid>
                            <Divider />
                            <form encType="multipart/form-data" onSubmit={this.submitHandler}>
                                <p className="kscsc-form">{this.props.t('Complaint.form-head')}</p>
                                <Grid item xs={12} md={12}>
                                    <h4 className="form-sub-head">{this.props.t('Complaint.SubTitle1')}</h4>
                                </Grid>

                                <Grid
                                    spacing={2}
                                    container>
                                    <Grid item xs={12} md={12}>
                                        <p className="label-input">{this.props.t('Complaint.Name')}</p>
                                        <div className=" MuiFormControl-root MuiTextField-root inputStyleBasic MuiFormControl-marginNormal" >
                                            <ReactTransliterate
                                                name="applicant_name"
                                                value={applicant_name}
                                                placeholder={this.props.t('Complaint.Name')}
                                                className="translate-input"
                                                autoComplete={(lang === "mal") ? "off" : ""}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={e => { (lang === "mal") ? this.handleTextTranslation(e, "applicant_name") : this.changeHandler(e) }}
                                                lang={(lang === "mal") ? "ml" : ""}
                                            /></div>

                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.applicant_name ? this.state.error.applicant_name : ''}</p>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <p className="label-input">{this.props.t('Complaint.Name_en')} </p>
                                        <TextField
                                            name="name_english"
                                            value={name_english}
                                            helperText={
                                                this.state.error.name_english ? this.state.error.name_english : ''
                                            }
                                            placeholder={this.props.t('Complaint.Name_en')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.handleTextChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <h4 className="form-sub-head">{this.props.t('Complaint.SubTitle2')}</h4>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.House_no')}</p>
                                        <TextField
                                            name="house_no"
                                            helperText={
                                                this.state.error.house_no ? this.state.error.house_no : ''
                                            }
                                            value={house_no}
                                            placeholder={this.props.t('Complaint.House_no')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.changeHandler}
                                        />
                                    </Grid>
                                    {/* /**************************** */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.House_name')}</p>
                                        <div className=" MuiFormControl-root MuiTextField-root inputStyleBasic MuiFormControl-marginNormal" >
                                            <ReactTransliterate
                                                name="house_name"
                                                value={house_name}
                                                placeholder={this.props.t('Complaint.House_name')}
                                                className="translate-input"
                                                autoComplete={(lang === "mal") ? "off" : ""}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={e => { (lang === "mal") ? this.handleTextTranslation(e, "house_name") : this.changeHandler(e) }}
                                                lang={(lang === "mal") ? "ml" : ""}
                                            /></div>

                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.house_name ? this.state.error.house_name : ''}</p>
                                    </Grid>
                                    {/* .......................................... */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Pincode')}</p>
                                        <TextField
                                            name="pincode"
                                            helperText={
                                                this.state.error.pincode ? this.state.error.pincode : ''
                                            }
                                            value={pincode}
                                            placeholder={this.props.t('Complaint.Pincode')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.changeHandler}
                                        />
                                    </Grid>
                                    {/* /**************************** */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.street')}</p>
                                        <div className=" MuiFormControl-root MuiTextField-root inputStyleBasic MuiFormControl-marginNormal" >
                                            <ReactTransliterate
                                                name="street"
                                                value={street}
                                                placeholder={this.props.t('Complaint.street')}
                                                className="translate-input"
                                                autoComplete={(lang === "mal") ? "off" : ""}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={e => { (lang === "mal") ? this.handleTextTranslation(e, "street") : this.changeHandler(e) }}
                                                lang={(lang === "mal") ? "ml" : ""}
                                            /></div>

                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.street ? this.state.error.street : ''}</p>
                                    </Grid>
                                    {/* .................................................. */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.District')}</p>
                                        <Select
                                            name="district"
                                            value={district}
                                            onChange={this.changeHandler}
                                            className="input-dropdown"
                                        // onClick={this.handleDistrictOption}
                                        // options={this.state.district_options}
                                        >
                                            <option aria-label="None" value={-1} disabled>{this.props.t('Complaint.District-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" ?
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Districts.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.districts}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.district ? this.state.error.district : ''}</p>
                                    </Grid>
                                    {/* /**************************** */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Taluk')}</p>
                                        <Select
                                            name="taluk"
                                            value={taluk}
                                            onChange={this.changeHandler}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" value={-1} disabled>{this.props.t('Complaint.Taluk-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" &&
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Taluks ?
                                                    this.props.complaint_data.Taluks.filter(item => item.district === this.state.data.district).map((item, index) => (
                                                        <option key={index} value={item.id}>{item.taluk}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.taluk ? this.state.error.taluk : ''}</p>
                                    </Grid>
                                    {/* .................................................. */}
                                    <Grid item xs={12} md={12}>
                                        <span className="label-notification"> {this.props.t('Complaint.phone_email_desc')}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Email')}</p>
                                        <TextField
                                            name="complaint_email"
                                            helperText={
                                                this.state.error.complaint_email ? this.state.error.complaint_email : ''
                                            }
                                            value={complaint_email}
                                            placeholder={this.props.t('Complaint.Email')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.changeHandler}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Phone_number')}</p>
                                        <TextField
                                            name="phone"
                                            value={phone}
                                            helperText={
                                                this.state.error.phone ? this.state.error.phone : ''
                                            }
                                            placeholder={this.props.t('Complaint.Phone_number')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.changeHandler}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Aadhar')}</p>
                                        <TextField
                                            name="aadhar"
                                            value={aadhar}
                                            helperText={
                                                this.state.error.aadhar ? this.state.error.aadhar : ''
                                            }
                                            placeholder={this.props.t('Complaint.Aadhar')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.changeHandler}
                                        />
                                    </Grid>
                                    {/* ......................................................... */}
                                    <Grid item xs={12} md={12}>
                                        <h4 className="form-sub-head">{this.props.t('Complaint.SubTitle3')}</h4>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <p className="label-input">{this.props.t('Complaint.complaint_type')}</p>
                                        <RadioGroup aria-label="complaint_type" name="complaint_type" value={complaint_type} onChange={this.changeHandler}>
                                            <FormControlLabel className="radio-label" value={this.props.t('Complaint.service')} control={<Radio />} label={this.props.t('Complaint.service')} />
                                            <FormControlLabel className="radio-label" value={this.props.t('Complaint.corruption')} control={<Radio />} label={this.props.t('Complaint.corruption')} />
                                        </RadioGroup>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.complaint_type ? this.state.error.complaint_type : ''}</p>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Subject')}</p>
                                        <Select
                                            name="subject"
                                            value={subject}
                                            onChange={this.changeHandler}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" disabled value="-1">{this.props.t('Complaint.Subject-default')}</option>
                                            <option value={this.props.t('Complaint.Subject_val1')}>{this.props.t('Complaint.Subject_val1')}</option>
                                            <option value={this.props.t('Complaint.Subject_val2')}>{this.props.t('Complaint.Subject_val2')}</option>
                                            <option value={this.props.t('Complaint.Subject_val3')}>{this.props.t('Complaint.Subject_val3')}</option>
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.subject ? this.state.error.subject : ''}</p>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.District')}</p>

                                        <Select
                                            name="complaint_district"
                                            value={complaint_district}
                                            onChange={this.changeHandler}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" disabled value="-1" >{this.props.t('Complaint.District-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" ?
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Districts.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.districts}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.complaint_district ? this.state.error.complaint_district : ''}</p>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Offices')}</p>
                                        <Select
                                            name="outlet"
                                            value={outlet}
                                            onChange={this.changeHandler}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" disabled value="-1" >{this.props.t('Complaint.Office-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" &&
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Outlets ?
                                                    this.props.complaint_data.Outlets.filter(item => item.district === this.state.data.complaint_district).map((item, index) => (
                                                        <option key={index} value={item.id}>{item.outlet}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.outlet ? this.state.error.outlet : ''}</p>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <p className="label-input">{this.props.t('Complaint.Complaint_Message')} </p>
                                        <div className=" MuiFormControl-root MuiTextField-root inputStyleBasic MuiFormControl-marginNormal" >
                                            <ReactTransliterate
                                                name="complaint_message"
                                                value={complaint_message}
                                                placeholder={this.props.t('Complaint.Complaint_Message')}
                                                className="translate-input translate-textarea"
                                                autoComplete={(lang === "mal") ? "off" : ""}
                                                margin="normal"
                                                variant="outlined"
                                                Component="textarea"
                                                onChange={e => { (lang === "mal") ? this.handleTextTranslation(e, "complaint_message") : this.changeHandler(e) }}
                                                lang={(lang === "mal") ? "ml" : ""}
                                            /></div>

                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.complaint_message ? this.state.error.complaint_message : ''}</p>
                                    </Grid>
                                    {/* ----------------------------------------------------------------- */}
                                    <Grid item xs={12} md={12}>
                                        <h4 className="form-sub-head">{this.props.t('Complaint.SubTitle5')}</h4>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Reference_Number')}</p>
                                        <TextField
                                            name="reference"
                                            value={reference}
                                            helperText={
                                                this.state.error.reference ? this.state.error.reference : ''
                                            }
                                            placeholder={this.props.t('Complaint.Reference_Number')}
                                            className="inputStyleBasic"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={this.handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Date')}</p>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="MM/dd/yyyy"
                                                value={selectedDate}
                                                onChange={this.handleDateChange}
                                                className="input-date-box"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    {/* .......................................... */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.District1')}</p>
                                        <Select
                                            name="reference_district"
                                            value={reference_district}
                                            onChange={this.handleTextChange}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" disabled value="-1" >{this.props.t('Complaint.District-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" ?
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Districts.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.districts}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.reference_district ? this.state.error.reference_district : ''}</p>
                                    </Grid>
                                    {/* /**************************** */}
                                    <Grid item xs={12} md={6}>
                                        <p className="label-input">{this.props.t('Complaint.Old-Offices')}</p>
                                        <Select
                                            name="reference_office"
                                            value={reference_office}
                                            onChange={this.handleTextChange}
                                            className="input-dropdown"
                                        >
                                            <option aria-label="None" disabled value="-1" >{this.props.t('Complaint.Office-default')}</option>
                                            {
                                                this.props.complaint_data !== "undefined" &&
                                                    this.props.complaint_data.Districts && this.props.complaint_data.Districts.length > 0 &&
                                                    this.props.complaint_data.Outlets ?
                                                    this.props.complaint_data.Outlets.filter(item => item.district === this.state.data.reference_district).map((item, index) => (
                                                        <option key={index} value={item.id}>{item.outlet}</option>
                                                    ))
                                                    : ""
                                            }
                                        </Select>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.reference_office ? this.state.error.reference_office : ''}</p>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <p className="label-input">{this.props.t('Complaint.reference_message')}</p>
                                        <div className=" MuiFormControl-root MuiTextField-root inputStyleBasic MuiFormControl-marginNormal" >
                                            <ReactTransliterate
                                                name="message"
                                                value={message}
                                                placeholder={this.props.t('Complaint.reference_message_default')}
                                                className="translate-input translate-textarea"
                                                autoComplete={(lang === "mal") ? "off" : ""}
                                                margin="normal"
                                                variant="outlined"
                                                Component="textarea"
                                                onChange={e => { (lang === "mal") ? this.handleTextTranslation(e, "message") : this.changeHandler(e) }}
                                                lang={(lang === "mal") ? "ml" : ""}
                                            /></div>

                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained input-dropdown-error">
                                            {this.state.error.message ? this.state.error.message : ''}</p>
                                    </Grid>
                                    {/* ------------------------------------------- */}
                                    {/* ...................................................... */}
                                    <Grid item xs={12} md={12} >
                                        <Grid item xs={12} md={12}>
                                            <h4 className="form-sub-head">{this.props.t('Complaint.SubTitle4')}</h4>
                                        </Grid>
                                        <Grid
                                            spacing={2}
                                            container>
                                            <ComplaintDocumentsList documents={this.state.data.documents} onItemCompleted={this.markItemCompleted} onDeleteItem={this.handleDeleteItem} />
                                        </Grid>
                                        <Grid
                                            spacing={2}
                                            container>
                                            <Grid item xs={12} md={2}>
                                                {this.state.data.documents.length === 0 &&
                                                    <p className="label-input mobile-doc-details-none">{this.props.t('Complaint.Document_slno')}</p>
                                                }
                                                <TextField
                                                    value={this.state.data.documents.length + 1}
                                                    variant="outlined"
                                                    className="inputStyleBasic inputStyleBasic-disabled mobile-doc-details-none"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {this.state.data.documents.length === 0 &&
                                                    <p className="label-input">{this.props.t('Complaint.Document_Name')}</p>}
                                                <TextField
                                                    name="document_name"
                                                    value={this.state.data.document_name}
                                                    helperText={
                                                        this.state.error.document_name ? this.state.error.document_name : ''
                                                    }
                                                    placeholder={this.props.t('Complaint.Document_Name')}
                                                    className="inputStyleBasic"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={this.handleTextChange}
                                                    onBlur={this.translateText}
                                                    onKeyDown={this.handleTextTranslation}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {this.state.data.documents.length === 0 &&
                                                    <p className="label-input">{this.props.t('Complaint.Document_Type')}</p>}
                                                <Select
                                                    name="document_type"
                                                    value={this.state.data.document_type}
                                                    onChange={this.handleFileType}
                                                    className="input-dropdown"
                                                >
                                                    <option value="document">Document</option>
                                                    <option value="image">Image</option>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                {this.state.data.documents.length === 0 &&
                                                    <p className="label-input">{this.props.t('Complaint.Document_File')}</p>}
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    name="document_file"

                                                >
                                                    Upload File
                                                    <input type="file" onChange={this.handleUpload} hidden />
                                                </Button>
                                                <p hidden={this.state.doc_upload_err} className="upload-file-color label-input">{this.props.t('Complaint.file-upload-err')}</p>
                                                <p hidden={this.state.data.doc_upload_name} className="upload-file-name label-input">{this.state.data.document_file.name}</p>
                                                <p hidden={this.state.doc_file_doc} className="upload-file-color label-input">{this.props.t('Complaint.doc-type-error')}</p>
                                                <p hidden={this.state.doc_file_img} className="upload-file-color label-input">{this.props.t('Complaint.doc-type-error1')}</p>
                                                <p hidden={this.state.doc_file_type} className="upload-file-color label-input">{this.props.t('Complaint.doc-file-type')}</p>
                                            </Grid>
                                            <Grid item xs={12} md={1} className="text-lg-center text-md-left text-left doc-btn">
                                                <button className="btn btn-doc-add  " onClick={this.handleAddItem} ><i className="las la-plus-circle"></i></button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <p hidden={this.state.doc_upload_data_err} className="label-notification upload-file-color label-input">{this.props.t('Complaint.doc_upload_data_err')}</p>
                                            <span className="label-notification"> {this.props.t('Complaint.document_size_desc')}</span>
                                        </Grid>
                                    </Grid>
                                    {/* .......................................................... */}
                                    <Grid item xs={12}>
                                        {this.props.google_capcha_site_key !== undefined ? <ReCAPTCHA className="recaptcha_align"
                                            sitekey={this.props.google_capcha_site_key !== '' ? this.props.google_capcha_site_key : process.env.GOOGLE_CAPTCHA_SITE_KEY}
                                            onChange={this.onChange}
                                        /> : ''}
                                    </Grid>
                                    {this.state.form_loader && <Loader />}
                                    {!this.state.form_loader ? (
                                        <Grid item xs={12}>
                                            <Button type="submit" id="complaint_submit"
                                                className="cBtn cBtnShadow mt-20 pr-50 pl-50">{this.props.t('Complaint.final-send')}</Button>
                                        </Grid>
                                    ) : ""}
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} md={2}>
                </Grid>
            </Grid >
        );
    }
}

const mapStateToProps = state => {
    return {
        google_capcha_site_key: state.header.google_capcha_site_key,
        complaint_data: state.complaint_data,
        loading: state.app.loading,
        selectedLanguage: state.app.selectedLanguage
    }
};

export default connect(mapStateToProps, { loadFetchComplaintAction })(withTranslation('common')(withRouter(ComplaintFormDetails)));


