import React, { Fragment } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import NewsDetails from "../../components/NewsDetails";
import { loadHomeAction } from "../../store/actions/action";

class LatestNewsDetails extends React.Component {

    componentDidMount() {
        let params = {
            banner_limit: 4,
            store_limit: 16,
            ansment_limit: 30
        };
        this.props.loadHomeAction(params);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage />
                <NewsDetails props={this.props.match} />
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        latest_news: state.home.latest_news
    };
};

export default connect(mapStateToProps, { loadHomeAction })(LatestNewsDetails);
