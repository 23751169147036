import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie'
import Footer from "../../components/Footer";
import ComplaintFormDetails from "../../components/ComplaintFormDetails";
import SubImage from "../../components/SubImage";
import { loadFetchComplaintAction } from "../../store/actions/action";

import './style.scss';

class ComplaintForm extends Component {
    state = {
        lang: ''
    };
    componentDidMount() {
        this.props.loadFetchComplaintAction()
        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            props.loadFetchComplaintAction()
        }

        return true;
    }
    render() {
        return (
            <Fragment>
                <SubImage />
                <ComplaintFormDetails />
                <Footer />
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        press_relase: state.press_release
    }
};

export default connect(mapStateToProps, { loadFetchComplaintAction })(ComplaintForm);