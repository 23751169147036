import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { loadSearchAction } from "../../store/actions/action";
import Loader from '../Loader';
import Cookies from 'js-cookie'

import './style.scss';

const ListSearch = (props) => {
    useEffect(() => {
        let params = {
            "searchKey": props.match.params.key
        };
        props.loadSearchAction(params);
    }, [Cookies.get('lang'), props.match.params.key]);

    return (
        <Grid >
            <section className="mt-md-5 mt-3 mb-3 mb-md-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 Blog_Style">
                            <h2>{props.t('SearchData.Title') + " “ " + props.match.params.key + " ”"} </h2>
                            <Divider className="special_hr_tag" />
                        </div>
                    </div>
                    <div className="row search_listing">
                        <div className="col-md-12">
                            {props.loading && <Loader />}
                            {!props.loading && props.search !== undefined && (Object.keys(props.search).length != 0) ? props.search.map((searchItem) => {
                                {
                                    return ((Object.keys(searchItem).length != 0) ? searchItem.map((item, keyData) => {
                                        return (
                                            <div className="border pl-3 pr-3 pt-3 mt-4" key={keyData}>
                                                {item.component === "ProductList" ?
                                                    <Link to={{ pathname: item.slug, product_data: { product_id: item.id } }} > {item.title}</Link>
                                                    : <Link to={{ pathname: item.slug }}>{item.title}</Link>
                                                } <Divider />
                                                {
                                                    item.description !== undefined && item.description !== "" ?
                                                        <p>{item.description}</p> : <p></p>
                                                }
                                            </div>
                                        )
                                    }) : '')
                                }
                            }) : <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                className="paginate-container"
                            >
                                    <span className="no-data">{props.t('SearchData.noData')}</span>
                                </Grid>}
                        </div>
                    </div>
                </div>
            </section>
        </Grid >
    )
};

const mapStateToProps = state => {
    return {
        search: state.search_data,
        loading: state.app.loading
    }
};

export default connect(mapStateToProps, { loadSearchAction })(withTranslation('common')(withRouter(ListSearch)));
