import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { postRequest } from "../../utils/request";

import Cookies from 'js-cookie'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import './style.scss';
import { Typography } from '@material-ui/core';

import logo from '../../images/home/logo.png';

class Footer extends Component {

    state = {
        email: '',
        error: {},
    };

    schema = {
        email: Joi.string()
            .required()
            .email()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case 'string.email':
                            err.message = 'Email Must be Email Format';
                            break;
                        case 'any.required':
                            err.message = 'Email is Requared';
                            break;
                        default:
                            break;
                    }
                });
                return errors;
            }),
    };

    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error,
        });
    };


    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] };
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        const form = {
            email: this.state.email,
        };
        const { error } = Joi.validate(form, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (const item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    submitHandler = event => {
        event.preventDefault();
        const error = this.validate();
        this.setState({
            error: error || {},
        });
        if (!error) {

            postRequest('subscription-process', {
                email: this.state.email
            })
                .then(res => {
                    if (res.data.success !== false) {
                        toast.success(res.data.message);
                        this.setState({
                            email: '',
                        })
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Not Send!');
                });
        }
    };

    render() {

        const { header } = this.props;
        return (
            <Grid className="footerWrapper">
                <Grid container className="container cont-footer" spacing={4}>
                    <Grid item xs={12} md={3} lg={3} className="pl-0">
                        <Grid className="footerContact text-md-left text-center">
                            <Link to="/"><img className="img-logo-footer" src={header.logo ? header.logo : logo} alt="footer-logo" /></Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} className="pl-0">
                        <Grid className="text-sm-left">
                            <Typography className="Footer-contact" variant="h3" component="h3">{this.props.t('Footer.ContactUs')}</Typography>
                            <Typography className="contact-val" component="p">{header.address ? header.address : 'The Kerala State Civil Supplies Corporation Ltd, Maveli Road, Gandhi Nagar, Kochi - 682 020'} </Typography>
                            <Typography className="contact-phone" component="p">{this.props.t('Footer.Phone')}: {header.contact_number ? header.contact_number : '0484 2206775, 2206795'} </Typography>
                            <Typography className="contact-phone" component="p" ><a className="contact-phone" href={"mailto:" + header.primary_email}>{this.props.t('Footer.Email')}: {header.primary_email ? header.primary_email : this.props.t('Footer.PrimaryMail')} </a></Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6} lg={3} className="pl-0">
                        <Grid className="follow-center">
                            <Typography className="Footer-contact" variant="h3" component="h3">{this.props.t('Footer.FollowUs')}</Typography>
                            <Grid >
                                <Link to="#" className="icons-spacing">
                                    <FacebookIcon fontSize="large" />
                                </Link>
                                <Link to="#" className="icons-spacing">
                                    <TwitterIcon fontSize="large" />
                                </Link>
                                <Link to="#" className="icons-spacing">
                                    <MailOutlineIcon fontSize="large" />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="container-fluid footerBottom">
                    <ul className=" list-unstyled footer-nav text-center">
                        <li> <Link to={{ pathname: `http://www.supplycomail.com/src/login.php` }} target="_blank" >{this.props.t('Footer.SupplycoMail')}</Link></li>
                        <li><Link to="/complaint-form">{this.props.t('Footer.Complaints')}/{this.props.t('Footer.Suggestions')}</Link></li>
                        <li><Link to={{ pathname: `http://www.supplycokerala.com/admin.htm` }} target="_blank" >{this.props.t('Footer.Portals')}</Link></li>
                        <li><Link to={{ pathname: `http://117.239.158.236/rc/search_rc/rationcard_wise_search.php` }} target="_blank" >{this.props.t('Footer.SubsidyAvailedDetails')}</Link></li>
                        <li><Link to="#">{this.props.t('Footer.VendorDetails')}</Link></li>
                    </ul>
                </Grid>
                <div className="copyright">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-6 text-md-left">
                                <p className="mb-0 d-inline"> {header.copyright_text ? header.copyright_text : this.props.t('Footer.Copyright')}</p>
                            </div>
                            {Cookies.get('visitorCount') ?
                                <div className="col-md-6 text-md-right">
                                    <p className="mb-0 d-inline">{this.props.t('Footer.Title1')}
                                        <span className="visitors-count">&nbsp;{Cookies.get('visitorCount') ? Cookies.get('visitorCount') : this.props.visitCount}</span>
                                        <sup>th</sup> {this.props.t('Footer.Title2')}.</p>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="fixed-info">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-9 text-center text-md-left">
                                <h6 className="mb-0 t3-weight">{this.props.t('Footer.Title3')} ?</h6>
                            </div>
                            <div className="col-sm-3 text-center text-sm-right">
                                <Link to="/contact-us" className="btn-success btn mt-2 mt-sm-0">{this.props.t('Footer.ContactUs')}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid >
        );
    }
}

const mapStateToProps = state => {
    return {
        header: state.header,
        visitCount: state.app.visitorCount
    }
};

export default connect(mapStateToProps)(withTranslation('common')(Footer));