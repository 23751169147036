import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Loader from '../Loader';

import "./style.scss";
class HomeAnnouncements extends React.Component {
    render() {
        const { announcements } = this.props;
        const { loading } = this.props;
        const onHover = () => {
            document.getElementById('mymarquee2').stop()
        }
        const onDown = () => {
            document.getElementById('mymarquee2').start()
        }
        return (
            <>
                {loading && <Loader />}
                {!loading ?
                    announcements !== undefined && (Object.keys(announcements).length != 0) ? (
                        <div className="col-md-6 col-lg-7 align-self-center">
                            <div className=" corporation_listing">
                                <h4>{this.props.t("Home.KSCSCCommonSection.annoncement")}</h4>
                                <ul onMouseOver={onHover} onMouseOut={onDown}>
                                    <marquee behavior="scroll" direction="up" scrollamount="5" id="mymarquee2">
                                        {announcements.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link className="l-color" to={`/announcement-details/${btoa(item.id)}`}>
                                                        {item.title}{" "}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </marquee>
                                </ul>
                            </div>
                        </div>
                    ) : (
                            <div className="col-md-6 align-self-center">
                                <div className=" corporation_listing">
                                    <h5 className="l-color">{this.props.t("Home.KSCSCCommonSection.CountZero")}</h5>
                                </div>
                            </div>
                        )
                    : ''}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        announcements: state.home.announcements,
        loading: state.app.loading
    };
};

export default connect(mapStateToProps)(
    withTranslation("common")(HomeAnnouncements)
);
