import React, { Fragment } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import Announcements from "../../components/Announcements";

import { loadHomeAction } from "../../store/actions/action";
class AnnouncementsDetails extends React.Component {

    componentDidMount() {
        let params = {
            banner_limit: 4,
            store_limit: 16,
            ansment_limit: 30
        };
        this.props.loadHomeAction(params);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <SubImage />
                <Announcements props={this.props.match} />
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        hero: state.home,
        announcements: state.home.announcements
    };
};

export default connect(mapStateToProps, { loadHomeAction })(
    AnnouncementsDetails
);
