import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";

import './style.scss';

const Loader = () => {

    return(
        <Grid container justify="center" className="root-grid">
            <CircularProgress className="loader-pos" />
        </Grid>
    );
};

export default Loader;
