
import React, { Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import Footer from "../../components/Footer";
import HomeAnnouncements from "../../components/HomeAnnouncements";
import Loader from '../../components/Loader';
import { loadHomeAction } from "../../store/actions/action";

const HomeBanner = React.lazy(() => import('../../components/HomeBanner'));
const LatestNews = React.lazy(() => import('../../components/LatestNews'));
const HODCommonSection = React.lazy(() => import('../../components/HODCommonSection'));
const ServiceCommonSection = React.lazy(() => import('../../components/ServiceCommonSection'));
const StoreCategorySection = React.lazy(() => import('../../components/StoreCategorySection'));
class HomePage extends React.Component {

    componentDidMount() {
        if (Object.keys(this.props.home).length === 0) {
            let params = {
                "banner_limit": "all",
                "store_limit": 16,
                "ansment_limit": 30
            };
            this.props.loadHomeAction(params);
        }
    }

    render() {
        return (
            <Fragment>
                <Suspense fallback={<Loader />}>
                    <HomeBanner />
                    <LatestNews />
                    <HODCommonSection />
                    <ServiceCommonSection />
                    <StoreCategorySection />
                    <Footer />
                </Suspense>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
};

export default connect(mapStateToProps, { loadHomeAction })(HomePage);