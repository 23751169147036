import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader';

import "./style.scss";

const getAnnounceDetails = (announcements, id) => {
    let announcement_details = [];

    if (announcements !== undefined)
        announcement_details = announcements.filter(announce => announce.id == id);
    return announcement_details[0];
};

const Announcements = props => {
    let announcement_details = getAnnounceDetails(props.announcements, atob(props.props.params.id));
    return (
        <>
            {props.loading && <Loader />}
            {!props.loading &&announcement_details !== undefined ? (
                <Grid className="mt-md-5 mb-md-5 mt-3 mb-3">
                    <Grid className="container announce-details" container spacing={4}>
                        <Grid item xs={12}>
                            <Grid className="prtdRightContent">
                                <h2>{announcement_details && announcement_details.title}</h2>
                                <Divider />
                            </Grid>
                            <Grid className="prtdRightContent">
                                <p>{announcement_details && announcement_details.description}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                    ""
                )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        announcements: state.home.announcements,
        loading: state.app.loading
    };
};

export default connect(mapStateToProps)(withTranslation("common")(Announcements));
