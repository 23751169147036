import React, { Fragment } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import DetailsPressRelease from "../../components/DetailsPressRelease";
import {loadPressReleaseAction} from "../../store/actions/action";
import Cookies from 'js-cookie'

class PressReleaseDetails extends React.Component {
    state = {
        lang: ''
    };
    componentDidMount() {
        if(Object.keys(this.props.press_relase).length === 0){
            this.props.loadPressReleaseAction()
        }
        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            props.loadPressReleaseAction()
        }

        return true;
    }
    render() {
        return (
            <Fragment>
                <SubImage />
                <DetailsPressRelease props={this.props.match} />
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        press_relase : state.press_release
    };
};

export default connect(mapStateToProps, { loadPressReleaseAction })(
    PressReleaseDetails
);
