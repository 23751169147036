import React from "react";
import { Switch } from "react-router-dom";

// Route Setting
import HomePage from "../HomePage";
import AboutUs from "../AboutUs";
import Services from "../Services";
import TeamPage from "../TeamPage";
import Portfolio from "../Portfolio";
import Gallery from "../Gallery";
import PortfolioSingle from "../PortfolioSingle";
import BlogLists from "../BlogLists";
import BlogSingle from "../BlogSingle";
import ContactUsPage from "../ContactUsPage";
import Announcements from "../AnnouncementsDetails";
import NewsDetails from "../LatestNewsDetails";
import Page from "../Page";
import GalleryList from "../GalleryList";
import PressRelease from "../PressRelease";
import PressReleaseDetails from "../PressReleaseDetails";
import SalesTurnOver from "../SalesTurnOver";
import GalleryVideo from "../GalleryVideo";
import GalleryVideoList from "../GalleryVideoList";
import OutletStrength from "../OutletStrength";
import AnnoucementList from "../AnnoucementList";
import ComplaintForm from "../ComplaintForm";
// Normal Route
import PrivateRoute from "../_PrivateRoute";
import PublicRoute from "../_PublicRoute";
import ProductList from "../ProductList";
import StoreList from "../StoreList";
import EtenderList from "../EtenderList";
import CareerList from "../CareerList";
import SiteMapList from "../SiteMapList";
import SearchList from "../SearchList";
import HoDivisionPage from "../HoDivisionPage";

function componentShowRender(copm) {
    switch (copm) {
        case "HomePage":
            return HomePage;
        case "AboutUs":
            return AboutUs;
        case "Services":
            return Services;
        case "Announcements":
            return Announcements;
        case "EtenderList":
            return EtenderList;
        case "HoDivisionPage":
            return HoDivisionPage;
        case "TeamPage":
            return TeamPage;
        case "Portfolio":
            return Portfolio;
        case "Gallery":
            return Gallery;
        case "BlogLists":
            return BlogLists;
        case "ContactUsPage":
            return ContactUsPage;
        case "Page":
            return Page;
        case "PressRelease":
            return PressRelease;
        case "SalesTurnOver":
            return SalesTurnOver;
        case "GalleryVideo":
            return GalleryVideo;
        case "GalleryVideoList":
            return GalleryVideoList;
        case "OutletStrength":
            return OutletStrength;
        case "AnnoucementList":
            return AnnoucementList;
        case "CareerList":
            return CareerList;
        case "ComplaintForm":
            return ComplaintForm;
        default:
            return HomePage;
    }
}

export default function Routes(props) {
    return (
        <Switch>
            <PrivateRoute exact fixHeader={true} path="/" component={HomePage} />

            {props.menus !== undefined
                ? props.menus.map((menu, i) => {
                    return (
                        <PrivateRoute
                            key={i}
                            fixHeader={menu.component === "HomePage" ? true : true}
                            exact
                            path={`/${menu.slug}`}
                            component={componentShowRender(menu.component)}
                        />
                    );
                })
                : ""}
            {props.menus !== undefined
                ? props.menus.map((menu, i) => {
                    return menu.submenu ? menu.submenu.map((subitem, j) => {
                        return (
                            <PrivateRoute
                                key={i + j}
                                fixHeader={menu.component === "HomePage" ? true : true}
                                exact
                                path={`/${subitem.slug}`}
                                component={componentShowRender(subitem.component)}
                            />
                        )
                    }) : ""

                })
                : ""}

            <PrivateRoute
                path="/announcement-details/:id"
                fixHeader={true}
                component={Announcements}
            />
            <PrivateRoute
                path="/news-details/:id"
                fixHeader={true}
                component={NewsDetails}
            />
            <PrivateRoute
                path="/our-products"
                fixHeader={true}
                component={ProductList}
            />
            <PrivateRoute
                path="/store-details/:id"
                fixHeader={true}
                component={StoreList}
            />
            <PrivateRoute
                path="/e-tenders"
                exact
                fixHeader={true}
                component={EtenderList}
            />
            <PrivateRoute
                path="/careers"
                fixHeader={true}
                component={CareerList}
            />
            <PrivateRoute
                path="/site-map"
                fixHeader={true}
                component={SiteMapList}
            />
            <PrivateRoute
                path="/search/:key"
                fixHeader={true}
                component={SearchList}
            />
            <PrivateRoute
                path="/ho-divisions"
                fixHeader={true}
                component={HoDivisionPage}
            />
            <PrivateRoute path="/complaint-form" fixHeader={true} component={ComplaintForm} />
            <PrivateRoute path="/contact-us" fixHeader={true} component={ContactUsPage} />
            <PrivateRoute path="/portfolio-single/:id" component={PortfolioSingle} />
            <PrivateRoute exact path="/blog/:id" component={BlogLists} />
            <PrivateRoute path="/blog-details/:id" component={BlogSingle} />
            <PrivateRoute path="/gallery" component={Gallery} />
            <PrivateRoute path="/cms/:slug" fixHeader={true} component={Page} />
            <PrivateRoute path="/gallery-list/:id" fixHeader={true} component={GalleryList} />
            <PrivateRoute path="/press-release" fixHeader={true} component={PressRelease} />
            <PrivateRoute path="/press-release-details/:id" fixHeader={true} component={PressReleaseDetails} />
            <PrivateRoute path="/turn-over" fixHeader={true} component={SalesTurnOver} />
            <PrivateRoute path="/gallery-video" fixHeader={true} component={GalleryVideo} />
            <PrivateRoute path="/gallery-video-list/:id" fixHeader={true} component={GalleryVideoList} />
            <PrivateRoute path="/outlet-strength" fixHeader={true} component={OutletStrength} />
            <PrivateRoute path="/announcement-list" fixHeader={true} component={AnnoucementList} />
        </Switch>
    );
}
