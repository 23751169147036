import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import GalleryVideoCategory from "../../components/GalleryVideoCategory";
import {withRouter} from 'react-router-dom';
import Cookies from 'js-cookie'

import {loadGalleryCategoryAction} from "../../store/actions/action";
class GalleryVideo extends Component {
    state = {
        lang: ''
    };

    componentDidMount() {
            const type = "/video";
            this.props.loadGalleryCategoryAction(type);

        this.setState({
            lang: Cookies.get('lang')
        })
        window.scrollTo(0, 0);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            const type = "/video";
            props.loadGalleryCategoryAction(type);
        }

        return true;
    }

    render(){
        return (
            <Fragment>
                <SubImage/>
                <GalleryVideoCategory/>
                <Footer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        video_categories: state.gallery.videoCategory
    }
};

export default connect(mapStateToProps, {loadGalleryCategoryAction})(withRouter(GalleryVideo));