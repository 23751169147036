import React, { Fragment, useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Lightbox from 'react-image-lightbox';
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { withTranslation } from "react-i18next";
import Loader from '../Loader';

import 'react-image-lightbox/style.css';
import './style.scss';

const PortfolioMasonary = (props) => {
    const image_list = useSelector(state => state.gallery.imageList);
    const loading = useSelector(state => state.app.loading);
    const [isOpen, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    function onOpenHandler(id) {
        setOpen(true);
        setPhotoIndex(id);
    }

    useEffect(() => {
        isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    },[isOpen]);

    return (
        <Fragment>
            <Grid container className="container mt-5">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('ImageCategory.Title')}</h5>
                        <Divider/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="allImages">
                <Grid container className="container">
                        {loading && <Loader />}
                        {!loading &&image_list !== undefined && image_list.length>0 ? image_list.map((item,index) => {
                            return (
                                <Grid key={index} item sm={6} md={4} xs={12} onClick={() => onOpenHandler(index)}>
                                    <Grid className="expertTeam-gallery-image image-grid mb-15">
                                        <Grid className="thumb">
                                            <img className="gallery-image" src={item.image} alt="GalleryImages"/>
                                        </Grid>
                                        <Grid className="content">
                                            <h4>{item.name}</h4>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        )
                    }) : <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        className="no-data"
                    >
                            <h5 className="">{props.t("ImageCategory.noData")}</h5>
                        </Grid>}
                </Grid>
                {isOpen && (
                    <Lightbox
                        mainSrc={image_list[photoIndex].image}
                        nextSrc={image_list[(photoIndex + 1) % image_list.length].image}
                        prevSrc={image_list[(photoIndex + image_list.length - 1) % image_list.length].image}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + image_list.length - 1) % image_list.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % image_list.length)}
                    />
                )}
            </Grid>
        </Fragment>
    )
};

export default (withTranslation('common')(PortfolioMasonary));