import React from 'react';
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader';
import moment from "moment";

import './style.scss';

const ListAnnouncements = (props) => {
    const announcements = useSelector(state => state.announcements);
    const loading = useSelector(state => state.app.loading);

    return (
        <Grid className="heading">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('AnnouncementList.Title')}</h5>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="container" container spacing={4}>
                <Grid item xs={12}>
                    <Grid className="portfolioList">
                        <Grid container spacing={4}>
                            {loading && <Loader />}
                            {!loading && announcements !== undefined && announcements.length > 0 ? announcements.map(item => {
                                return (
                                    <Grid key={item.id} item xs={12} sm={6} >
                                        <Grid className="press_shadow border">
                                            <Grid>
                                                {props.t('PressRelease.PublishDate')}: {moment(item.date).format('DD/MM/YYYY')}
                                            </Grid>
                                            <Divider />
                                            <h3>{item.title}</h3>
                                            <Grid className="special-btn-block text-right top-details">
                                                <Link to={`/announcement-details/${btoa(item.id)}`} className="btn-outline btn active">
                                                    {props.t('PressRelease.Details')}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }) : ''}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default (withTranslation('common')(withRouter(ListAnnouncements)));