import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader';

import bgShape2 from '../../images/bg-shape2.png';
import download from '../../images/downloads.png';

import './style.scss';

class CMSSection extends React.Component {

    render() {
        const { page } = this.props;
        const { loading } = this.props;
        return (
            <Grid className="CMSSection">
                <Grid container>
                    <Grid item xs={12}>
                        {loading && <Loader />}
                        {!loading ?
                            page !== undefined && page.id != null ? (
                                <>
                                    {page.type == "content" ? (
                                        <Grid className="container" container>
                                            <Grid item xs={12}>
                                                <Grid className="prtdRightContent">
                                                    <h5>{page.title && page.title}</h5>
                                                    <Divider />
                                                </Grid>
                                                <Grid className="prtdRightContent page-sub-container">
                                                    <div dangerouslySetInnerHTML={{ __html: page.content }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                            <Grid className="cmsWrapper cmsFeaturs">
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    className="container xlg-pt-50"
                                                    justify="center"
                                                >
                                                    <Grid item xs={12} md={4} sm={6}>
                                                        <Grid className="cmsItem">
                                                            <Grid className="icon">
                                                                <img src={download} alt="" />
                                                            </Grid>
                                                            <Grid className="content">
                                                                <Link to={{ pathname: page.content_file }} target="_blank" download><h2>Click Here</h2></Link>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <img className="bgShape2" src={bgShape2} alt="bgShape2" />
                                            </Grid>
                                        )}
                                </>
                            )
                                : (page !== undefined && page.hasOwnProperty('success') && page.success == false ?
                                    (<div className="page-wrap d-flex flex-row align-items-center">
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-md-12 text-center">
                                                    <span className="display-1 d-block">404</span>
                                                    <div className="mb-4 ">The page you are looking for was not found.</div>
                                                    <Link className="btn btn-link" to={"/"}>Back to Home</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : ""
                                ) : ""
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        page: state.page,
        loading: state.app.loading
    }
};

export default connect(mapStateToProps)(withTranslation('common')(CMSSection));