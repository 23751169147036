import React,{useEffect,useState} from 'react'
import {withTranslation} from "react-i18next";
import {Line} from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import Loader from '../Loader';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';

import './style.scss';

const OutletsStrength = (props) => {
    const outlet_strength= useSelector(state => state.outlet_strength);
    const loading = useSelector(state => state.app.loading);

    const [data, setData] = useState();
    let outletCount
    let outletValue
    let outletData = []

    let getRandomColor = () =>{
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function outletDataSets(item){
        if(item){
            return(
                {
                    label: item,
                    fill: false,
                    // lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: getRandomColor(),
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: outlet_strength.stores[item],
                }
            )
        }
    }

    useEffect(() => {
        if(outlet_strength!==undefined && outlet_strength.stores !== undefined ){
            outletCount = Object.keys(outlet_strength.stores).length;
            Object.keys(outlet_strength.stores).map((item) => {
                outletValue = outletDataSets(item)
                outletData.push(outletValue)
                if(outletData!==[] && outletData.length === outletCount){
                    setData({
                        labels: outlet_strength.year,
                        datasets: outletData
                    })
                 }
            });
        }
    }, [outlet_strength]);

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: props.t('OutletStrength.Xaxis')
                }
            }],
            yAxes: [{
                gridLines: {
                    display: true,
                },
                ticks: {
                    beginAtZero: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: props.t('OutletStrength.Yaxis')
                }
            }],
        },
        legend: {
            display: true,
            position:'top'
        },
        tooltips: {
            enabled: true,
        },
    };

    return (
        <div className="chart">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style graph-heading">
                        <h5>{props.t('OutletStrength.Title')}</h5>
                        <Divider/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="container">
                {loading && <Loader />}
                {!loading && outlet_strength!==undefined && data !== undefined ? (
                    <Line
                        data={data}
                        height={450}
                        width={600}
                        options={lineOptions}
                    />)
                :""
                }
            </Grid>
        </div>
  )
}

export default (withTranslation('common')(withRouter(OutletsStrength)));