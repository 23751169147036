import React, { Fragment } from "react";
import { connect,useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Loader from '../Loader';
import "./style.scss";

const getNewsDetails = (latest_news, id) => {
    let news_details = [];

    if (latest_news !== undefined)
        news_details = latest_news.filter(news => news.id == id);
    return news_details[0];
};

const NewsDetails = props => {
    const loading = useSelector(state => state.app.loading);
    let news_details = getNewsDetails(props.latest_news, atob(props.props.params.id));

    return (
        <>
            {loading && <Loader />}
            {!loading && news_details !== undefined ? (
                <Grid className="mt-md-5 mb-md-5 mt-3 mb-3">
                    <Grid className="container" container spacing={4}>
                        <Grid item xs={12}>
                            <Grid className="prtdRightContent">
                                <h2>{news_details && news_details.title}</h2>
                                <hr />
                            </Grid>
                            <Grid className="prtdRightContent">
                                <p>{news_details && news_details.description}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                    ""
                )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        latest_news: state.home.latest_news
    };
};

export default connect(mapStateToProps)(withTranslation("common")(NewsDetails));
