import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import CustomDataTable from '../CustomDataTable';
import Typography from '@material-ui/core/Typography';
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from '../Loader';
import { loadStoreAction } from "../../store/actions/action";
import Cookies from 'js-cookie'
import moment from "moment";

import './style.scss';

const ListStore = (props) => {
    useEffect(() => {
        fetchStore();
    }, [Cookies.get('lang')]);
    function fetchStore(page = 1, limit = 10, sort = "") {
        let params = {
            "store_limit": limit,
            "page": page,
            "sort": sort,
            "store_category_id": props.match.params.id
        };
        props.loadStoreAction(params);
    }
    /**  Table configurations */
    const columns = [
        {
            name: props.t('Store.store-name'),
            selector: 'title',
            sortable: true,
            width: "40%",
        },
        {
            name: props.t('Store.store-desc'),
            selector: 'description',
            sortable: true,
            width: "60%",
            cell: (item) => {
                return item.description;
            }
        },
    ];

    return (
        <Grid className="allstores">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('Store.title')}</h5>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        {props.loading && <Loader />}
                        {
                            !props.loading && props.stores !== undefined ?
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    className="paginate-container"
                                >
                                    <CustomDataTable
                                        noHeader={true}
                                        columns={columns}
                                        tableData={props.stores}
                                        tableAction={fetchStore}
                                        striped={true}
                                        pagination={true}
                                        paginationServer={true}
                                        className="table table-bordered table-striped tender_table_style stripe"
                                    />
                                </Grid>
                                : ""
                        }
                    </Grid >
                </Grid >
            </Grid >
        </Grid >
    )
};

const mapStateToProps = state => {
    return {
        stores: state.store,
        loading: state.app.loading
    }
}
export default connect(mapStateToProps, { loadStoreAction })(withTranslation('common')(withRouter(ListStore)));
