import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { loadHoDivisions } from "../../store/actions/action";
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Loader from '../Loader';
import Cookies from 'js-cookie'

import './style.scss';

const HoDivision = (props) => {
    var lang= Cookies.get('lang');
    useEffect(() => {
        props.loadHoDivisions();
    }, [lang]);

    const [expanded, setExpanded] = useState('panel0');

    const handleChange = panel => (event, expanded) => {
        let exp = expanded ? panel : false;
        setExpanded(exp);
    };

    return (
        <Grid>
            <section className="mt-md-5 mt-3 mb-3 mb-md-5 ho-division">
                <Grid container className="container">
                    <Grid item xs={12} md={12} lg={12} >
                        <Grid className="Blog_Style">
                            <h5>{props.t('Hodivisions.Title')}</h5>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="container">
                    <Grid item xs={12} md={12} lg={12} >
                        {props.loading && <Loader />}
                        {!props.loading &&
                            props.hodData !== undefined && Object.keys(props.hodData).length != 0
                            ? props.hodData.map((item, parentKey) => {

                                return (
                                    <div key={parentKey} className="row mt-30">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <ExpansionPanel
                                                    square
                                                    expanded={expanded === 'panel' + parentKey}
                                                    onChange={handleChange('panel' + parentKey)}
                                                    className="card"
                                                >
                                                    <ExpansionPanelSummary>
                                                        <div className="card-header bg-grey">
                                                            <h2 className="mb-0">
                                                                <a className="  text-left text-black" >
                                                                    {item.title}
                                                                </a>
                                                                {item.file !== undefined && item.file !== "" ?
                                                                    <Link to={{ pathname: item.file }} className="accordian-icons" target="_blank">
                                                                        <i className="fa fa-file-pdf-o" aria-hidden="true" title="Click here view the Staff Details"></i>
                                                                    </Link>
                                                                    : ""}
                                                            </h2>
                                                        </div>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <div className="card-body"> {item.description} </div>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            : ""}
                    </Grid>
                </Grid>
            </section>
        </Grid >
    );
}


const mapStateToProps = state => {
    return {
        hodData: state.hoDivision,
        loading: state.app.loading
    }
};

export default connect(mapStateToProps, { loadHoDivisions })(withTranslation('common')(withRouter(HoDivision)));
