import React, { Fragment, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import ListStore from "../../components/ListStore";
import Footer from "../../components/Footer";
import SubImage from "../../components/SubImage";
import { loadStoreAction } from "../../store/actions/action";

class StoreList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Fragment>
                <SubImage />
                <ListStore />
                <Footer />
            </Fragment >
        )
    }
};

const mapStateToProps = state => {
    return {
        stores: state.store
    }
};

export default connect(mapStateToProps, { loadStoreAction })(StoreList);