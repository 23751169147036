import React from 'react';
import { useSelector } from 'react-redux';
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import {withRouter,Link} from "react-router-dom";
import Loader from '../Loader';

import './style.scss';

const GalleryVideoCategory = (props) => {
    const gallery = useSelector(state => state.gallery.videoCategory);
    const loading = useSelector(state => state.app.loading);

    return (
        <>
            <Grid container className = "allTeam">
                <Grid container className="container">
                    <Grid item xs={12} md={12} lg={12} >
                        <Grid className="Blog_Style">
                            <h5>{props.t('VideoList.Title')}</h5>
                            <Divider/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="container">
                    {loading && <Loader />}
                    {!loading && gallery != undefined && gallery.length > 0? gallery.map(item => {
                        return (
                            <Grid key={item.id} item sm={6} md={4} xs={12} lg={4}>
                                <Grid className="expert-Team gallery-img-b">
                                    <Grid className="thumbnl video-boundry">
                                        <Link to={`/gallery-video-list/${btoa(item.id)}`} >
                                            <img className="img-width" src={item.videos[0]!= undefined ? item.videos[0].image : item.videos.image} alt="thumbnail"/>
                                        </Link>
                                    </Grid>
                                    <Grid className="caption">
                                        <Link to={`/gallery-video-list/${btoa(item.id)}`} >
                                            <h3>{item.title}</h3>
                                            <p>{item.videos_count !== undefined ? item.videos_count : "no"} videos</p>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }) : ''}
                </Grid>
            </Grid>
        </>
    );
};

export default (withTranslation('common')(withRouter(GalleryVideoCategory)));






