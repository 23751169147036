
import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux'


import ComplaintDocuments from "../ComplaintDocuments"


class ComplaintDocumentsList extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.documents !== "undefined" && this.props.documents.length > 0 ?
                        <>
                            <Grid item xs={12} md={2}>
                                <p className="label-input mobile-doc-details-none">{this.props.t('Complaint.Document_slno')}</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <p className="label-input mobile-doc-details-none">{this.props.t('Complaint.Document_Name')}</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <p className="label-input mobile-doc-details-none">{this.props.t('Complaint.Document_Type')}</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <p className="label-input mobile-doc-details-none">{this.props.t('Complaint.Document_Selected_File')}</p>
                            </Grid>
                            <Grid item xs={12} md={1} className="text-lg-center  text-md-left text-left doc-btn">
                            </Grid>
                            {this.props.documents.map((item, index) => (
                                <ComplaintDocuments key={item.id} slno={index + 1} id={item.id} documents={item} completed={item.done} onItemCompleted={this.props.onItemCompleted} onDeleteItem={this.props.onDeleteItem} />
                            ))}
                        </>

                        : ""
                }
            </>
        );
    }

}
const mapStateToProps = state => {
    return {
        complaint_data: state.complaint_data
    }
};
export default withTranslation('common')(connect(mapStateToProps)(ComplaintDocumentsList));