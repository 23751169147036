import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import subImage from '../../images/sub-banner/supplyco-banner2.jpg';

import './style.scss';

const SubImage = () => {
    // const rejectReason = useSelector(state => state.gallery.home_banners);

    return (

        <>
            <Grid container>
                <Grid item className="sub-image-border">
                    <img className="d-block w-100" src={subImage} alt="First slide" />
                </Grid>
            </Grid>
        </>
    );
};

export default (withTranslation('common')(SubImage));