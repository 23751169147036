import React, { Fragment } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import OutletsStrength from "../../components/OutletsStrength";
import {loadOutletStrengthAction} from "../../store/actions/action";
import SubImage from "../../components/SubImage";
import Cookies from 'js-cookie'

class OutletStrength extends React.Component {
    state = {
        lang: ''
    };
    componentDidMount() {
        this.props.loadOutletStrengthAction();
        this.setState({
            lang: Cookies.get('lang')
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLanguage != "" && state.lang != "" && props.selectedLanguage !== state.lang) {
            state.lang = props.selectedLanguage;
            props.loadOutletStrengthAction();
        }
        return true;
    }

    render() {
        return (
            <Fragment>
                <SubImage/>
                <OutletsStrength/>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedLanguage: state.app.selectedLanguage,
        outlet : state.outlet_strength
    };
};

export default connect(mapStateToProps, { loadOutletStrengthAction })(
    OutletStrength
);
