import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { loadProductAction } from "../../store/actions/action";
import Loader from '../Loader';

import './style.scss';

const ListProducts = (props) => {
    let product_id = 'all';
    if (props.location.product_data !== undefined)
        product_id = (props.location.product_data.product_id > 0) ? props.location.product_data.product_id : 'all';

    useEffect(() => {
        let params = {
            "product_limit": 9,
            "page": 1,
            "product_id": product_id
        };
        props.loadProductAction(params);
    }, [Cookies.get('lang')]);

    const { current_page, per_page, total } = props.products;

    const handlePageChange = (pageNumber) => {
        let params = {
            "product_limit": 9,
            "page": pageNumber,
            "product_id": product_id
        };
        props.loadProductAction(params);
    }

    return (
        <Grid className="allProducts">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style">
                        <h5>{props.t('Home.ServiceCommonSection.Title1')}</h5>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="container">
                {props.loading && <Loader />}
                {!props.loading && props.products.data !== undefined ? props.products.data.map(item => {
                    return (
                        <Grid key={item.id} item sm={6} lg={4} md={4} xs={12}>
                            <Grid className="expertTeam product-grid mb-15">
                                <Grid className="thumb">
                                    {item.product_images['0'] !== undefined ? (
                                        <img src={item.product_images['0'].image} alt="" />
                                    ) : ""}
                                </Grid>
                                <Grid className="content">
                                    <h4>{item.title}</h4>
                                    <Divider />
                                    <Grid className="price-block text-center">
                                        <Grid className="current-price">
                                            <small>INR</small>{item.price}
                                        </Grid>
                                        <Grid className="offer-price">
                                            <h3> <small>INR</small> {item.original_price}</h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }) : ''}
                {!props.loading && (Object.keys(props.products).length != 0) ?
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        className="paginate-container"
                    >
                        <Grid item >
                            <Pagination
                                activePage={current_page}
                                itemsCountPerPage={parseInt(per_page)}
                                totalItemsCount={total}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                firstPageText="First"
                                lastPageText="Last"
                            />
                        </Grid>
                    </Grid>
                    : ""}
            </Grid>
        </Grid>
    )
};

const mapStateToProps = state => {
    return {
        products: state.product,
        loading: state.app.loading
    }
};

export default connect(mapStateToProps, { loadProductAction })(withTranslation('common')(withRouter(ListProducts)));
