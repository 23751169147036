import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';

import './style.scss';

class ComplaintDocuments extends Component {

    constructor(props) {
        super(props);
        this.markCompleted = this.markCompleted.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }
    markCompleted(event) {
        this.props.onItemCompleted(this.props.id);
    }
    deleteItem(event) {
        this.props.onDeleteItem(this.props.id);
    }
    render() {
        return (
            <>
                <Grid item xs={12} md={2} className=" mobile-doc-details-none">
                    <TextField
                        value={this.props.slno}
                        variant="outlined"
                        className="inputStyleBasic inputStyleBasic-disabled mobile-doc-details-none"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={3} className=" mobile-doc-details-none">
                    <TextField
                        name="document_name"
                        value={this.props.documents.document_name}
                        className="inputStyleBasic  inputStyleBasic-disabled"
                        margin="normal"
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={3} className=" mobile-doc-details-none">
                    <TextField
                        name="document_type"
                        value={this.props.documents.document_type}
                        className="inputStyleBasic  inputStyleBasic-disabled"
                        margin="normal"
                        variant="outlined"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={3} className=" mobile-doc-details-none">
                    {/* <p>Filename: {this.props.documents.document_file.name}</p>
                    <p>File type: {this.props.documents.document_file.type}</p> */}
                    {this.props.documents.document_file &&
                        (this.props.documents.document_file.type == "image/jpeg" || this.props.documents.document_file.type == "image/png" || this.props.documents.document_file.type == "image/jpg") ?
                        this.props.documents.document_file && <ImageThumb image={this.props.documents.document_file} />
                        : <p><i className="lar la-file-pdf"></i>Filename: {this.props.documents.document_file.name}</p>}
                    <p>File size: {this.props.documents.document_file.size} bytes</p>
                </Grid>
                <Grid item xs={12} md={1} className="text-lg-center  text-md-left text-left doc-btn mobile-doc-details-none">
                    <button type="button" className="btn btn-danger btn-sm btn-doc-del" onClick={this.deleteItem}><i className="las la-trash"></i></button>
                </Grid>
                <Grid item xs={12} className="desktop-doc-view-none mob-doc-view   ">

                    <Grid item xs={12} md={3} className="">

                        {this.props.documents.document_file &&
                            (this.props.documents.document_file.type == "image/jpeg" || this.props.documents.document_file.type == "image/png" || this.props.documents.document_file.type == "image/jpg") ?
                            this.props.documents.document_file && <ImageThumb image={this.props.documents.document_file} />
                            : <p className="doc-label-input"><i className="lar la-file-pdf"></i>Filename: {this.props.documents.document_file.name}</p>}

                        <p className="doc-label-input">{this.props.t('Complaint.Document_Name')} {this.props.documents.document_name} </p>
                        <p className="doc-label-input">File size: {this.props.documents.document_type} </p>
                        <p className="doc-label-input">File size: {this.props.documents.document_file.size} bytes</p>
                        <button type="button" className="btn btn-danger btn-sm btn-doc-del" onClick={this.deleteItem}><i className="las la-trash"></i></button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        complaint_data: state.complaint_data
    }
};

export default withTranslation('common')(connect(mapStateToProps)(ComplaintDocuments));
/**
 * Component to display thumbnail of image.
 */
const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} />;
};
