import React,{useEffect,useState} from 'react'
import {withTranslation} from "react-i18next";
import {Bar} from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';

import './style.scss';

const TurnOverGraph = (props) => {
    const turn_over= useSelector(state => state.sales_turn_over);
    const [amount, setAmount] = useState();
    const [year, setYear] = useState();
    const [backgdColorCheck, setBackgdColorCheck] = useState();
    const [hoverBackgdColorCheck, setHoverBackgdColorCheck] = useState();
    let salesAmount = [];
    let salesYear = [];

    let backgdColor=['rgba(54, 158, 173, 1)','rgba(194, 70, 66, 1)','rgba(127, 96, 132, 1)',
        'rgba(134, 180, 2, 1)','rgba(162, 209, 207, 1)','rgba(200, 182, 49,1)','rgba(109, 188, 235, 1)',
        'rgba(82, 81, 78, 1)','rgba(79, 129, 188, 1)','rgba(160, 100, 161, 1)','rgba(247, 150, 71, 1)',
        'rgba(54, 158, 173, 1)','rgba(194, 70, 66, 1)','rgba(127, 96, 132, 1)','rgba(134, 180, 2, 1)',
        'rgba(162, 209, 207, 1)','rgba(200, 182, 49,1)','rgba(109, 188, 235, 1)','rgba(82, 81, 78, 1)',
        'rgba(79, 129, 188, 1)','rgba(160, 100, 161, 1)','rgba(247, 150, 71, 1)','rgba(54, 158, 173, 1)',
        'rgba(194, 70, 66, 1)','rgba(127, 96, 132, 1)','rgba(134, 180, 2, 1)','rgba(162, 209, 207, 1)',
        'rgba(200, 182, 49,1)','rgba(109, 188, 235, 1)'
    ]

    let hoverBackgdColor=['rgba(54, 158, 173, 0.6)','rgba(194, 70, 66, 0.6)','rgba(127, 96, 132, 0.6)',
        'rgba(134, 180, 2, 0.6)','rgba(162, 209, 207, 0.6)','rgba(200, 182, 49,0.6)','rgba(109, 188, 235, 0.6)',
        'rgba(82, 81, 78, 0.6)','rgba(79, 129, 188, 0.6)','rgba(160, 100, 161, 0.6)','rgba(247, 150, 71, 0.6)',
        'rgba(54, 158, 173, 0.6)','rgba(194, 70, 66, 0.6)','rgba(127, 96, 132, 0.6)','rgba(134, 180, 2, 0.6)',
        '0.6)','0.6)','0.6','0.6)',
        '0.6','rgba(160, 100, 161, 0.6)','rgba(247, 150, 71, 0.6)','rgba(54, 158, 173, 0.6)',
        'rgba(194, 70, 66, 0.6)','rgba(127, 96, 132, 0.6)','rgba(134, 180, 2, 0.6)','rgba(162, 200.6, 207, 0.6)',
        '0.6)','0.6','rgba(54, 158, 173, 0.6)'
    ]

    function colourBackground(){
        if(turn_over!==undefined){
            if(turn_over.length>30){
                backgdColor = backgdColor.concat(backgdColor);
                hoverBackgdColor = hoverBackgdColor.concat(hoverBackgdColor);
                setBackgdColorCheck(backgdColor);
                setHoverBackgdColorCheck(hoverBackgdColor);
            }else{
                backgdColor = backgdColor;
                hoverBackgdColor = hoverBackgdColor;
                setBackgdColorCheck(backgdColor);
                setHoverBackgdColorCheck(hoverBackgdColor);
            }
        }
    }

    useEffect(() => {

        if(turn_over!==undefined && turn_over.length>0){
            colourBackground();
            turn_over.map((item) => {
                salesAmount.push(item.amount);
                salesYear.push(item.year);
            });
            setAmount(salesAmount);
            setYear(salesYear);
        }
    }, [turn_over]);

    let salesData={chartData:{
        labels: year,
        datasets:[
            {
                label:props.t('TurnOver.Yaxis'),
                data:amount,
                backgroundColor:backgdColorCheck,
                borderWidth:1,
                borderColor:"#777",
                hoverBackgroundColor:hoverBackgdColorCheck,
                hoverBorderWidth:2,
                hoverBorderColor:"#000",
                maxBarThickness : 100
            }
        ]
    }}

    return (
        <Grid container className="chart">
            <Grid container className="container">
                <Grid item xs={12} md={12} lg={12} >
                    <Grid className="Blog_Style graph-heading">
                        <h5>{props.t('TurnOver.Title')}</h5>
                        <Divider/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="container">
                {turn_over!==undefined && salesAmount!==[] && salesYear!==[] && backgdColorCheck!==[] ?(
                    <Bar
                        data = {salesData.chartData}
                        height = {400}
                        width = {600}
                        options = {{
                        responsive : true,
                        maintainAspectRatio : false,
                        scales : {
                            yAxes : [
                            {
                                ticks : {
                                    beginAtZero : true,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: props.t('TurnOver.Yaxis'),
                                    fontSize: 25
                                }
                            },
                            ],
                            xAxes : [{
                                gridLines: {
                                    display: false,
                                },
                            }]
                        },
                        legend : {
                            display : false,
                            position : "left"
                        }
                        }}
                    />
                ) : "" }
            </Grid>
        </Grid>
  )
}

export default (withTranslation('common')(withRouter(TurnOverGraph)));